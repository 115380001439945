import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import checkout, { CheckoutState } from '@/store/modules/checkout'
import auth, { AuthState } from '@/store/modules/auth'
import config from '@/config'

Vue.use(Vuex)

export interface RootState {
  checkout: CheckoutState
  auth: AuthState
}

const store: Store<RootState> = new Vuex.Store({
  strict: config.rails_env !== 'production',

  actions: {
    init({ dispatch }) {
      dispatch('auth/init', { root: true })
      dispatch('checkout/init', { root: true })
    }
  },

  modules: {
    checkout,
    auth
  }
})

export default store
