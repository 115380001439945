export default function preloadImage(
  url: string
): Promise<{ image: HTMLImageElement; url: string }> {
  return new Promise((resolve, reject) => {
    const image = new Image()

    image.onload = () => {
      resolve({ image, url })
    }

    image.onerror = (e) => {
      reject(e)
    }

    image.src = url
  })
}
