import { ShippingCountry, ShippingMethod } from '@/types/Shipping'
import { Country } from '@/types/address'

// Load config data
const element = document.getElementById('config')
const config = element ? JSON.parse(element.innerText) : {}

interface Config {
  rails_env: string
  countries: Country[]
  code_version: any
  valid_country_codes: string[]
  google_tag_manager_id: string
  google_auth_client_id: string
  rollbar_token: string
  address_validation: {
    min_address_line_length: number
    max_address_line_length: number
    max_name_length: number
    max_zip_length: number
    min_city_length: number
    max_city_length: number
    max_state_length: number
    max_phone_length: number
  }
  shipping_countries: ShippingCountry[]
  shipping_methods: ShippingMethod[]
}

export default {
  ...config,

  shipping_countries: config.countries.filter((country: ShippingCountry) => {
    return config.valid_country_codes.includes(country.code)
  }) as ShippingCountry[],

  shipping_methods: [
    {
      id: 'standard',
      name: 'Standard Delivery'
    },
    {
      id: 'rush',
      name: 'Rush Delivery'
    },
    {
      id: 'super_rush',
      name: 'Super Rush Delivery'
    }
  ]
} as Config
