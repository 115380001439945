<template>
  <div class="OrderSummary text-left divide-y">
    <div v-for="orderItem in quote.items" :key="orderItem.cid" class="divide-y">
      <div v-for="(item, $index) in orderItem.products" :key="$index">
        <OrderSummaryItem
          :name="orderItem.name"
          :item="item"
          class="w-full py-4"
        />
      </div>
    </div>

    <div class="flex items-center justify-between py-4 font-semibold mt-1">
      <div>Subtotal</div>

      <div v-if="quote">
        {{ quote.subtotal | currency }}
      </div>
      <activity-indicator v-else size="small" />
    </div>

    <div class="flex items-center justify-between py-4 font-semibold">
      <div>
        Shipping &amp; Handling
      </div>

      <div>
        <div v-if="quote">
          <span v-if="quote.shipping > 0">
            {{ quote.shipping | currency }}
          </span>
          <span v-else>
            TBD
          </span>
        </div>
        <activity-indicator v-else size="small" />
      </div>
    </div>

    <div
      class="flex items-center justify-between py-4 font-semibold text-xl"
    >
      <div>Total</div>
      <div v-if="quote">
        {{ quote.total_price | currency }}
      </div>
      <activity-indicator v-else size="small" />
    </div>

    <div class="flex items-center py-4 text-sm">
      <Icon name="info" class="mr-1" size="16" />
      Customs charges may apply for orders outside of US
    </div>

    <div
      class="hidden lg:flex items-center justify-between mt-16 border-t py-8"
    >
      <div class="flex items-center">
        <img
          class="mr-6"
          width="100"
          src="@/assets/images/guarantee.svg"
          alt="100% Satisfaction Guaranteed"
        />

        <div>
          <div class="label mb-2">
            Our Guarantee
          </div>
          <div class="font-italic text-sm">
            We carefully review every <br />design to ensure a great print!
          </div>
        </div>
      </div>

      <div>
        <img
          class="mr-6 blend-multiply"
          width="100"
          src="@/assets/images/norton-security-seal.png"
          alt="Secured and encrypted checkout"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import OrderSummaryItem from '@/components/OrderSummaryItem.vue'

import { CartQuote } from '@/types/cart'

export default Vue.extend({
  components: {
    OrderSummaryItem
  },

  props: {
    quote: {
      type: Object as PropType<CartQuote>,
      required: true
    }
  }
})
</script>
