<template>
  <div class="CheckoutReviewView" data-testid="review">
    <div class="mb-8">
      <div class="flex items-center mb-4">
        <div class="title mb-0">
          <span v-if="!billingAddress">
            Shipping &amp; Billing Address
          </span>

          <span v-else>
            Shipping address
          </span>
        </div>

        <router-link
          :to="{ name: 'checkout.shipping' }"
          class="ml-auto link text-lg lg:hidden"
        >
          Edit
        </router-link>
      </div>

      <div class="border rounded p-6">
        <AddressBlock :address="{ email, ...shippingAddress }" />
      </div>
    </div>

    <div v-if="billingAddress" class="mb-8">
      <div class="flex items-center mb-4">
        <div class="title mb-0">
          Billing Address
        </div>

        <router-link
          :to="{ name: 'checkout.payment' }"
          class="ml-auto link text-lg lg:hidden"
        >
          Edit
        </router-link>
      </div>

      <div class="border rounded p-6 mt-1">
        <AddressBlock :address="billingAddress" />
      </div>
    </div>

    <div class="mb-8">
      <div class="flex items-center mb-4">
        <div class="title mb-0">
          Payment Method
        </div>

        <router-link
          :to="{ name: 'checkout.payment' }"
          class="ml-auto link text-lg lg:hidden"
        >
          Edit
        </router-link>
      </div>

      <div class="border rounded py-4 px-6">
        <PaymentMethodPreview :payment="paymentMethod" />
      </div>
    </div>

    <div class="lg:hidden">
      <div class="title">
        Order Items
      </div>

      <OrderSummary
        v-if="quote"
        :quote="isLoadingQuote ? null : quote"
        class="-mx-6"
      />
    </div>

    <hr class="-mx-6 lg:hidden mt-4 mb-8" />

    <div>
      <PayPalButton
        v-if="paymentMethod.method === 'paypal'"
        key="paypal"
        class="w-full"
        :amount="amount"
        data-testid="payByPayPalButton"
        @authorize="handlePaypalPaymentAuthorized"
      />

      <AppButton
        v-else
        key="paypal"
        class="w-full"
        primary
        data-testid="placeOrderButton"
        @click="placeOrder"
      >
        Place Order
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'

import AnalyticsService from '@/services/AnalyticsService'

import AddressBlock from '@/components/AddressBlock.vue'
import PayPalButton from '@/components/PayPalButton.vue'
import OrderSummary from '@/components/OrderSummary.vue'
import PaymentMethodPreview from '@/components/PaymentMethodPreview.vue'

export default Vue.extend({
  components: {
    AddressBlock,
    PayPalButton,
    OrderSummary,
    PaymentMethodPreview
  },

  computed: {
    ...mapGetters('checkout', [
      'email',
      'quote',
      'cartItems',
      'isLoadingQuote',
      'shippingAddress',
      'billingAddress',
      'paymentMethod'
    ]),

    amount(): number {
      return this.quote?.total_price || 0
    }
  },

  methods: {
    async placeOrder() {
      this.$emit('submit')

      AnalyticsService.trackEvent(
        'international-checkout',
        'place-order',
        undefined,
        this.quote?.total_price
      )
    },

    handlePaypalPaymentAuthorized(result: { nonce: string }) {
      this.$store.dispatch('checkout/changePaymentMethod', {
        method: 'paypal',
        payment_method_nonce: result.nonce
      })

      this.placeOrder()
    }
  },

  metaInfo() {
    return {
      title: 'Review Your Order | Checkout'
    }
  }
})
</script>
