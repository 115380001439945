<template>
  <div class="TheHeader">
    <div class="container flex items-center py-4 lg:px-8">
      <router-link to="/">
        <CustomInkLogo full class="w-32" />
      </router-link>

      <div v-if="email" class="ml-auto mr-8 flex-grow text-right">
        {{ email }}
      </div>

      <div class="ml-auto">
        <div class="flex items-center">
          <img src="@/assets/images/headset-icon.svg" class="h-6 mr-3" />
          <div>
            <div class="uppercase text-xs">
              Talk To A Real Person
            </div>

            <a :href="`tel:${phoneNumber}`" class="font-semibold text-ci-red">
              {{ phoneNumber }}
            </a>
          </div>
        </div>
      </div>

      <!-- <div class="flex items-center pl-6 ml-6 border-l">
        <img src="@/assets/images/chat-icon.svg" class="h-6 mr-3" />
        <div>
          <div class="uppercase text-xs">Chat With A Real Person</div>
          <a href @click.prevent="$root.$emit('openChat', 'header')" class="font-semibold text-ci-red">Chat Now</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import supportPhoneNumbers from '@/constants/supportPhoneNumbers'
import CustomInkLogo from '@/components/CustomInkLogo.vue'

export default Vue.extend({
  components: {
    CustomInkLogo
  },

  props: {
    email: {
      type: String,
      default: ''
    },
    country: {
      type: String,
      required: false
    }
  },

  computed: {
    phoneNumber() {
      return supportPhoneNumbers[this.country] || supportPhoneNumbers['US']
    }
  }
})
</script>
