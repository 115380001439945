<template>
  <component
    :is="tagName"
    class="AppButton button"
    :class="{ 'isPrimary': primary, 'isSmall': small }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    small: {
      type: Boolean
    },

    primary: {
      type: Boolean
    }
  },

  computed: {
    tagName(): string {
      return this.$attrs.href ? 'a' : 'button'
    }
  }
})
</script>
