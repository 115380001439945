<template>
  <div class="OrderView">
    <portal to="title">
      <div class="flex items-center">
        <router-link :to="{ name: 'orders' }">
          Orders
        </router-link>

        <Icon name="caret-right" class="mx-1" />

        #{{ orderId }}

        <div class="ml-5 space-x-2 flex items-center">
          <div
            v-if="order && order.claimed_by"
            class="inline-block text-sm rounded-full py-1 px-3 bg-yellow-400"
          >
            claimed by
            <b>{{ claimedByFormatted }}</b>
          </div>

          <div
            v-if="order && order.note"
            class="inline-block text-sm rounded-full py-1 px-3 bg-yellow-400"
          >
            has a note
          </div>
        </div>
      </div>
    </portal>

    <div v-if="isLoading" class="flex">
      <activity-indicator size="large" class="mx-auto my-12" />
    </div>

    <div v-else class="space-y-5">
      <div class="flex items-stretch space-x-5">
        <div class="w-1/2">
          <div class="border rounded bg-white h-full divide-y">
            <div class="flex items-center justify-between px-6 py-4">
              <div class="font-bold">
                Order status
              </div>
              <OrderStatusBadge :status="order.status" />
            </div>

            <div class="flex items-center justify-between px-6 py-4">
              <div class="font-bold">
                CustomInk ID
              </div>
              <div v-if="order.customink_id">
                <a
                  :href="`https://www.customink.com/admin/sales?CSL=true#q=${order.customink_id}`"
                  target="_blank"
                  class="link"
                >
                  {{ order.customink_id }}
                </a>
              </div>
            </div>

            <div class="flex items-center justify-between px-6 py-4">
              <div class="font-bold">
                Design CID
              </div>
              <div>
                {{ order.cid }}
              </div>
            </div>

            <div class="flex items-center justify-between px-6 py-4">
              <div class="font-bold">
                Placed on
              </div>
              <div class="text-right">
                {{ order.created_at | datetime }}
              </div>
            </div>

            <div class="flex items-center justify-between px-6 py-4">
              <div class="font-bold">
                User's email
              </div>
              <div class="text-right">
                {{ order.email }}
              </div>
            </div>
          </div>
        </div>

        <div class="w-1/2">
          <div class="border rounded bg-white h-full">
            <div class="flex h-full divide-x">
              <div class="w-1/2">
                <div class="p-6 flex flex-col h-full">
                  <div class="font-bold text-xl mb-4">
                    Shipping Address
                  </div>

                  <AddressBlock :address="order.shipping_address" />
                </div>
              </div>

              <div class="w-1/2">
                <div class="p-6 flex flex-col h-full">
                  <div class="font-bold text-xl mb-4">
                    Billing Address
                  </div>

                  <AddressBlock :address="order.billing_address" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Order Items -->

      <div class="border p-6 rounded bg-white">
        <div class="font-bold text-xl mb-4">
          Order items
        </div>

        <table class="table mb-8">
          <thead>
            <tr>
              <th class="text-left w-2/3 lg:w-3/4">
                Product
              </th>

              <th class="text-center">
                Quantity
              </th>

              <th class="text-right">
                Price
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="isLoadingItems">
              <td colspan="3">
                <div class="flex min-h-32">
                  <activity-indicator size="large" class="m-auto" />
                </div>
              </td>
            </tr>

            <template v-else>
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <div class="flex items-center">
                    <div class="flex items-center space-x-2">
                      <div v-for="view in item.views" :key="view.url">
                        <LazyImage
                          :src="view.url"
                          class="w-32 h-32 border rounded"
                        />
                      </div>
                    </div>

                    <div class="ml-5">
                      <div class="font-semibold text-lg">
                        {{ item.product_name }}
                      </div>

                      <div class="text-muted">
                        {{ item.color_name }}
                      </div>
                    </div>
                  </div>
                </td>

                <td class="text-center">
                  {{ item.quantity }}
                </td>

                <td class="text-right">
                  {{ item.price | currency }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <div class="divide-y border-t">
          <div class="flex items-center justify-between py-3">
            <div>
              Subtotal
            </div>

            <div>{{ order.subtotal_price | currency }}</div>
          </div>

          <div class="flex items-center justify-between py-3">
            <div>
              Shipping
            </div>

            <div>{{ order.shipping_price | currency }}</div>
          </div>

          <div
            class="flex items-center justify-between py-3 font-semibold text-lg"
          >
            <div>
              Total price
            </div>

            <div>{{ order.total_price | currency }}</div>
          </div>
        </div>
      </div>

      <!-- Claims -->

      <div class="flex items-center border p-6 rounded bg-white">
        <div class="font-bold text-xl">
          Claims
        </div>

        <div class="flex items-center ml-auto space-x-5">
          <template v-if="order.claimed_by">
            <div class="mr-5">
              This order is claimed by
              <b>{{ claimedByFormatted }}</b>
            </div>

            <AppButton
              v-if="!isClaimedByCurrentUser"
              :disabled="isClaiming"
              small
              @click="claim()"
            >
              Reclaim
            </AppButton>

            <AppButton :disabled="isClaiming" small @click="unclaim()">
              Unclaim
            </AppButton>
          </template>

          <template v-else>
            <div class="text-muted italic mr-5">
              This order is not claimed by anyone
            </div>

            <AppButton :disabled="isClaiming" small @click="claim()">
              Claim this order
            </AppButton>
          </template>
        </div>
      </div>

      <!-- Notes -->

      <div class="border p-6 rounded bg-white">
        <div class="font-bold text-xl mb-4">
          Notes
        </div>

        <TextAreaInput
          v-model="order.note"
          rows="4"
          class="mb-4"
          placeholder="Add some notes to this order…"
          @input="touchNote()"
        />

        <div class="flex items-center justify-end">
          <div v-if="hasSaveNote" class="mr-5">
            <Icon name="check" class="text-green-500 mr-1" /> Saved
          </div>

          <AppButton
            small
            :disabled="isSavingNote || hasPristineNote"
            @click="saveNote()"
          >
            {{ isSavingNote ? 'Saving note' : 'Save note' }}
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import AddressBlock from '@/components/AddressBlock.vue'
import OrderStatusBadge from '@/components/admin/OrderStatusBadge.vue'

import OrdersService from '@/services/admin/OrdersService'
import CheckoutService from '@/services/CheckoutService'

import { Order, OrderItem } from '@/types/admin/orders'
import { mapGetters } from 'vuex'

export default Vue.extend({
  components: {
    AddressBlock,
    OrderStatusBadge
  },

  props: {
    orderId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      isLoading: true as boolean,
      isLoadingItems: true as boolean,
      order: {} as Order,
      items: [] as OrderItem[],
      isClaiming: false as boolean,
      isSavingNote: false as boolean,
      hasPristineNote: true as boolean,
      hasSaveNote: false as boolean
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    isClaimedByCurrentUser(): boolean {
      return this.order?.claimed_by === this.user.email
    },

    claimedByFormatted(): string {
      return this.isClaimedByCurrentUser ? 'you' : this.order.claimed_by!
    }
  },

  async mounted() {
    await this.loadOrder()
    await this.loadOrderItems()
  },

  methods: {
    async loadOrder() {
      this.isLoading = true

      this.order = await OrdersService.loadOrder(this.orderId)

      this.isLoading = false
    },

    async loadOrderItems() {
      this.isLoadingItems = true

      this.items = await OrdersService.loadOrderItems(this.order.cid)

      this.isLoadingItems = false
    },

    touchNote() {
      this.hasPristineNote = false
      this.hasSaveNote = false
    },

    async saveNote() {
      this.isSavingNote = true

      this.order = await OrdersService.saveNotes(this.orderId, this.order.note)

      this.hasPristineNote = true
      this.isSavingNote = false
      this.hasSaveNote = true
    },

    async claim() {
      this.isClaiming = true

      const order = await OrdersService.claimOrder(
        this.orderId,
        this.user.email
      )

      if (order) {
        this.order = order
      }

      this.isClaiming = false
    },

    async unclaim() {
      this.isClaiming = true

      const order = await OrdersService.claimOrder(this.orderId, undefined)

      if (order) {
        this.order = order
      }

      this.isClaiming = false
    }
  }
})
</script>
