<template>
  <tr
    class="text-sm group"
    :class="
      user.email === order.claimed_by
        ? 'bg-blue-200 bg-opacity-25 hover:bg-opacity-50'
        : 'hover:bg-gray-200'
    "
  >
    <td class="p-0 text-center">
      <router-link :to="orderRoute" class="link block py-3 pr-2">
        {{ order.id }}
      </router-link>
    </td>

    <td class="p-0 text-center">
      <a
        v-if="order.customink_id"
        :href="`https://home.customink.com/backend/orders/${order.customink_id}/saa_detail`"
        target="_blank"
        class="link block py-3 pr-2"
      >
        {{ order.customink_id }}
      </a>
    </td>

    <td class="text-center">
      {{ order.quantity }}
    </td>

    <td class="text-right">
      {{ order.total_price | currency }}
    </td>

    <td class="text-center">
      <OrderStatusBadge :status="order.status" />
    </td>

    <td class="text-center">
      {{ order.email }}
    </td>

    <td class="text-center">
      {{ order.created_at | datetime }}
    </td>

    <td class="text-center">
      <div
        v-if="order.note"
        class="inline-block text-xs rounded-full py-1 px-3 bg-yellow-400"
        :title="order.note"
      >
        has a note
      </div>
    </td>

    <td class="text-center cursor-default" @click.stop>
      <template v-if="order.claimed_by">
        <span class="leading-none">
          {{ order.claimed_by }}
        </span>

        <a
          v-if="user.email === order.claimed_by"
          href
          class="text-danger font-bold opacity-75 hover:opacity-100 inline-flex items-center justify-center w-6 h-6 align-middle"
          title="Unclaim"
          @click.prevent="unclaimOrder(order.id)"
        >
          <Icon name="close" />
        </a>
      </template>

      <template v-else>
        <span class="hidden group-hover:inline">
          <AppButton
            small
            class="min-h-0 py-1 px-3 text-sm -my-1"
            :disabled="isClaiming"
            @click="claimOrder(order.id)"
          >
            {{ isClaiming ? 'Claiming' : 'Claim' }}
          </AppButton>
        </span>
      </template>
    </td>
  </tr>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'

import OrderStatusBadge from '@/components/admin/OrderStatusBadge.vue'

import OrdersService from '@/services/admin/OrdersService'

import { Order } from '@/types/admin/orders'

export default Vue.extend({
  components: {
    OrderStatusBadge
  },

  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    }
  },

  data() {
    return {
      isClaiming: false as boolean
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    orderRoute(): any {
      return { name: 'order', params: { id: this.order.id } }
    }
  },

  methods: {
    async claimOrder(orderId: number) {
      this.isClaiming = true

      const updatedOrder = await OrdersService.claimOrder(
        orderId,
        this.user.email
      )

      if (updatedOrder) {
        this.$emit('update', updatedOrder)
      }

      this.isClaiming = false
    },

    async unclaimOrder(orderId: number) {
      if (!confirm('Unclaim current inker from this order?')) {
        return
      }

      this.$emit('update', {
        ...this.order,
        claimed_by: undefined
      })

      await OrdersService.claimOrder(orderId)
    }
  }
})
</script>
