<template>
  <div class="CreditCardInput">
    <div class="mb-6">
      <label class="label">Card number</label>

      <div class="relative">
        <div class="absolute z-1 left-0 inset-y-0 ml-3 flex items-center">
          <CreditCardIcon :card-type="cardType" class="w-6" />
        </div>

        <div
          id="creditCardInput-number"
          class="relative input h-10 p-0"
          :class="{ 'isDisabled': !hostedFields }"
        ></div>
      </div>
    </div>

    <div class="flex flex-wrap lg:flex-nowrap mb-6">
      <div class="w-full lg:w-1/3 mb-4 lg:mb-0">
        <label class="label">Expiration date</label>

        <div class="relative">
          <Icon
            name="calendar"
            class="absolute z-1 left-0 inset-y-0 w-10 ml-1 flex items-center justify-center pointer-events-none text-gray-500"
            :size="22"
          />

          <div
            id="creditCardInput-expirationDate"
            class="relative input h-10 p-0"
            :class="{ 'isDisabled': !hostedFields }"
          ></div>
        </div>
      </div>

      <div class="w-full lg:w-1/3 mb-4 lg:mb-0 lg:ml-5">
        <label class="label">CVC</label>

        <div class="relative">
          <Icon
            name="lock"
            class="absolute z-1 left-0 inset-y-0 w-10 ml-1 flex items-center justify-center pointer-events-none text-gray-500"
            :size="22"
          />

          <div
            id="creditCardInput-cvv"
            class="relative input h-10 p-0"
            :class="{ 'isDisabled': !hostedFields }"
          ></div>
        </div>
      </div>

      <div class="w-full lg:w-1/3 mb-4 lg:mb-0 lg:ml-5">
        <label class="label">Postal Code</label>

        <div class="relative">
          <Icon
            name="envelope"
            class="absolute z-1 left-0 inset-y-0 w-10 ml-1 flex items-center justify-center pointer-events-none text-gray-500"
            :size="22"
          />

          <div
            id="creditCardInput-postalCode"
            class="relative input h-10 p-0"
            :class="{ 'isDisabled': !hostedFields }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { hostedFields, HostedFieldsStateObject } from 'braintree-web'

import BraintreeService from '@/services/BraintreeService'

import CreditCardIcon from '@/components/CreditCardIcon.vue'

export interface CreditCardInfo {
  details: {
    cardType: string
    lastFour: string
  }
  nonce: string
}

export default Vue.extend({
  components: {
    CreditCardIcon
  },

  data() {
    return {
      cardType: undefined as any,
      hostedFields: undefined as any
    }
  },

  async mounted() {
    const client = await BraintreeService.getClientInstance()

    const options = {
      client,

      styles: {
        'input': {
          'color': '#282c37',
          'font-size': '17px',
          'transition': 'color 0.1s',
          'line-height': '3',
          'padding': '0 0 0 46px'
        },
        'input.isInvalid': {
          'color': '#c53030'
        },
        '::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder': {
          'color': 'rgba(0,0,0,0.4)'
        }
      },

      fields: {
        number: {
          selector: '#creditCardInput-number',
          placeholder: '1111 1111 1111 1111'
        },
        cvv: {
          selector: '#creditCardInput-cvv',
          placeholder: '123'
        },
        expirationDate: {
          selector: '#creditCardInput-expirationDate',
          placeholder: 'MM / YY'
        },
        postalCode: {
          selector: '#creditCardInput-postalCode',
          placeholder: '12345'
        }
      }
    }

    hostedFields.create(options, (error, hostedFieldsInstance) => {
      if (error) {
        throw error
      }

      // hostedFieldsInstance reference
      this.hostedFields = hostedFieldsInstance

      // This event is emitted when the validity of a field has changed.
      // Validity is represented in the stateObject as two booleans: isValid and isPotentiallyValid.
      this.hostedFields.on('validityChange', this.onValidityChange)

      // This event is emitted when activity within the number field has
      // changed such that the possible card type has changed.
      this.hostedFields.on('cardTypeChange', this.onCardTypeChange)

      // This event is emitted when a field
      // transitions from having data to being empty.
      this.hostedFields.on('empty', this.onEmpty)
    })
  },

  beforeDestroy() {
    this.hostedFields.off('validityChange', this.onValidityChange)
    this.hostedFields.off('cardTypeChange', this.onCardTypeChange)
    this.hostedFields.off('empty', this.onEmpty)
  },

  methods: {
    /*
      Return tokenized credit card information, e.g.:

      {
        type: "CreditCard"
        description: "ending in 06"
        details: {
          cardType: "MasterCard"
          lastFour: "4706"
          lastTwo: "06"
        }
        nonce: "card-token"
      }
    */

    tokenizeCard(): Promise<CreditCardInfo> {
      return new Promise((resolve, reject) => {
        if (!this.hostedFields) {
          return reject(
            new Error('Braintree hosted fields have not been initialized')
          )
        }

        const cardFormState = this.hostedFields.getState()

        // Loop through the Hosted Fields and check
        // for validity, apply the `isInvalid` class
        // to the field container if invalid
        Object.keys(cardFormState.fields).forEach((field) => {
          if (
            !cardFormState.fields[field].isValid &&
            !cardFormState.fields[field].isEmpty
          ) {
            cardFormState.fields[field].container.classList.add('isInvalid')
          }
        })

        this.hostedFields.tokenize((error: Error, payload: CreditCardInfo) => {
          if (error) {
            return reject(error)
          }

          this.$emit('token', payload)
          resolve(payload)
        })
      })
    },

    onValidityChange(event: HostedFieldsStateObject) {
      var field = event.fields[event.emittedBy]

      // Remove any previously applied error or warning classes
      field.container.classList.remove('isValid')
      field.container.classList.remove('isInvalid')

      if (field.isValid) {
        field.container.classList.add('isValid')
      } else if (field.isPotentiallyValid) {
        // skip adding classes if the field is
        // not valid, but is potentially valid
      } else {
        field.container.classList.add('isInvalid')
      }
    },

    onCardTypeChange(event: HostedFieldsStateObject) {
      // Change card bg depending on card type
      if (event.cards.length === 1) {
        // Set credit card type
        this.cardType = event.cards[0].niceType

        // Change the CVV length for AmericanExpress cards
        if (event.cards[0].code.size === 4) {
          this.hostedFields.setAttribute({
            field: 'cvv',
            attribute: 'placeholder',
            value: '1234'
          })
        }
      }

      // TODO this seems to be redundant
      this.hostedFields.setAttribute({
        field: 'cvv',
        attribute: 'placeholder',
        value:
          event.cards.length === 1 && event.cards[0].code.size === 4
            ? '1234'
            : '123'
      })
    },

    onEmpty() {
      this.cardType = undefined
    }
  }
})
</script>
