<template>
  <img :src="cardIcon" class="CreditCardIcon" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import imageUnknown from '@/assets/images/credit-cards/empty.svg'
import imageVisa from '@/assets/images/credit-cards/visa.svg'
import imageMaestro from '@/assets/images/credit-cards/maestro.svg'
import imageMasterCard from '@/assets/images/credit-cards/master-card.svg'
import imageAmericanExpress from '@/assets/images/credit-cards/american-express.svg'
import imageDiscover from '@/assets/images/credit-cards/discover.svg'
import imageDinersClub from '@/assets/images/credit-cards/diners-club.svg'
import imageJcb from '@/assets/images/credit-cards/jcb.svg'
import imageUnionpay from '@/assets/images/credit-cards/unionpay.svg'

const cardIcons: { [key: string]: any } = {
  'unknown': imageUnknown,
  'visa': imageVisa,
  'maestro': imageMaestro,
  'mastercard': imageMasterCard,
  'american express': imageAmericanExpress,
  'discover': imageDiscover,
  'diners club': imageDinersClub,
  'jcb': imageJcb,
  'unionpay': imageUnionpay
}

export default Vue.extend({
  props: {
    cardType: {
      type: String,
      default: 'unknown'
    }
  },

  computed: {
    cardIcon(): string {
      return cardIcons[this.cardType.toLowerCase()]
    }
  }
})
</script>

<style lang="postcss">
.CreditCardIcon {
  @apply inline-block w-32;
}
</style>
