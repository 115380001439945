<template>
  <label class="Radiobox" :class="{ 'isLarge': large }">
    <input
      class="Radiobox__input"
      type="radio"
      :checked="value"
      @change="change"
      v-on="$listeners"
    />
    <span class="Radiobox__virtualInput"></span>
    <span v-if="$slots.default" class="Radiobox__label">
      <slot />
    </span>
  </label>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: Boolean,
    large: Boolean
  },

  methods: {
    change(e: any) {
      this.$emit('input', e.target.checked)
    }
  }
})
</script>

<style lang="postcss">
.Radiobox {
  @apply inline-flex items-center select-none align-middle;
}

.Radiobox__input {
  @apply absolute opacity-0;
}

.Radiobox__virtualInput {
  @apply relative flex items-center justify-center w-5 h-5 border rounded-full cursor-pointer;
  top: -1px;

  .Radiobox.isLarge & {
    @apply w-6 h-6;
  }

  .Radiobox__input + &::after {
    @apply block bg-blue-500 rounded-full w-2 h-2 m-auto transition-transform duration-150;
    content: '';
  }

  .Radiobox__input:not(:checked) + &::after {
    transform: scale(0);
  }
}

.Radiobox__label {
  @apply inline-block ml-2 cursor-pointer;
}
</style>
