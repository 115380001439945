import Vue from 'vue'

import config from '@/config'
import router from '@/router'
import store from '@/store'

// Rollbar
import Rollbar from 'vue-rollbar'

Vue.use(Rollbar, {
  accessToken: config.rollbar_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: !['development', 'test'].includes(config.rails_env),
  source_map_enabled: true,
  environment: config.rails_env,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: config.code_version,
        guess_uncaught_frames: true
      }
    }
  }
})

Vue.config.errorHandler = (err) => {
  Vue.rollbar.error(err)
  console.error(err)
}

// Vue meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Activity indicator
import VueActivityIndicator from 'vue-activity-indicator'
Vue.use(VueActivityIndicator)

// Last Modal
import VueLastModal from 'vue-last-modal'
Vue.use(VueLastModal)

// Portal
import PortalVue from 'portal-vue'
Vue.use(PortalVue)

// Filters
import * as filters from '@/filters'

Object.entries(filters).forEach(([name, method]) => {
  Vue.filter(name, method)
})

// Global components
import * as globalComponents from '@/components/global'

Object.entries(globalComponents).forEach(([name, component]) => {
  Vue.component(name, component)
})

// Init App
import App from '@/App.vue'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    router,
    store,
    created: () => store.dispatch('init'),
    render: (h) => h(App)
  }).$mount()

  document.body.appendChild(app.$el)
})
