import debouncePromise from 'debounce-promise'
import { ActionTree, MutationTree, GetterTree } from 'vuex'

import config from '@/config'

import CheckoutService from '@/services/CheckoutService'
import StorageService from '@/services/StorageService'
import GeoipService from '@/services/GeoipService'
import ValidationService, {
  isValidAddress,
  isValidPaymentMethod,
  isValidEmail
} from '@/services/ValidationService'
import { CartItem, CartQuote } from '@/types/cart'
import { ShippingAddress, BillingAddress } from '@/types/address'
import { Payment } from '@/types/payment'
import { PlacedOrder } from '@/types/order'

import { RootState } from '@/store'

const CHECKOUT_STORAGE_KEY = 'checkout'

enum types {
  ITEMS_SET = 'ITEMS_SET',
  LOADING_QUOTE_SET = 'LOADING_QUOTE_SET',
  SHIPPING_ADDRESS_CHANGED = 'SHIPPING_ADDRESS_CHANGED',
  SHIPPING_METHOD_CHANGED = 'SHIPPING_METHOD_CHANGED',
  PAYMENT_METHOD_CHANGED = 'PAYMENT_METHOD_CHANGED',
  BILLING_ADDRESS_CHANGED = 'BILLING_ADDRESS_CHANGED',
  GEO_IP_COUNTRY_SET = 'GEO_IP_COUNTRY_SET',
  EMAIL_CHANGED = 'EMAIL_CHANGED',
  QUOTE_UPDATED = 'QUOTE_UPDATED',
  ERRORS_SET = 'ERRORS_SET',
  ORDER_PLACED = 'ORDER_PLACED',
  CHECKOUT_CLEARED = 'CHECKOUT_CLEARED',
  CART_INITIALIZED = 'CART_INITIALIZED'
}

export interface CheckoutState {
  cartItems: CartItem[]
  email?: string
  quote?: CartQuote
  shippingMethodId: string
  shippingAddress: ShippingAddress
  billingAddress: BillingAddress | undefined
  paymentMethod: Payment
  placedOrder?: PlacedOrder
  isLoadingQuote?: boolean
  isInitialized?: boolean
  geoIpCountry?: string
  errors: { [key: string]: any }
}

const getDefaultCheckoutData = (): CheckoutState => ({
  cartItems: [],
  email: undefined,
  quote: undefined,
  shippingMethodId: 'standard',
  shippingAddress: {} as ShippingAddress,
  billingAddress: undefined,
  paymentMethod: { method: 'card' } as Payment,
  placedOrder: undefined,
  geoIpCountry: undefined,
  errors: {}
})

const getPersistedCheckoutData = () =>
  StorageService.load(CHECKOUT_STORAGE_KEY, {})

const getInitialState = (): CheckoutState => ({
  ...getDefaultCheckoutData(),
  ...getPersistedCheckoutData(),
  isLoadingQuote: false,
  isInitialized: false
})

const state: CheckoutState = getInitialState()

const actions: ActionTree<CheckoutState, RootState> = {
  async init({ commit, dispatch, state }) {
    dispatch('fetchGeoipCountry')

    if (state.cartItems.length) {
      await dispatch('quote')
    }

    commit(types.CART_INITIALIZED)
  },

  async fetchGeoipCountry({ commit, dispatch, state }) {
    try {
      const country = await GeoipService.getCountry()

      // Don't rewrite country if it is already selected
      if (state.shippingAddress.country) {
        return
      }

      commit(types.GEO_IP_COUNTRY_SET, country)
      dispatch('changeShippingAddress', { ...state.shippingAddress, country })
    } catch (err) {
      console.log('Unable to retrieve country')
    }
  },

  async setCartItems({ commit, dispatch }, cartItems = []) {
    commit(types.ITEMS_SET, { cartItems })

    dispatch('persistCheckout')
    dispatch('quote')
  },

  quote: debouncePromise(async ({ commit, dispatch, state, getters }) => {
    if (!state.cartItems.length) {
      commit(types.QUOTE_UPDATED, { quote: undefined })
      return
    }

    commit(types.LOADING_QUOTE_SET, true)

    try {
      const shippingAddress = getters.shippingAddress?.certified
        ? state.shippingAddress
        : undefined

      const quote = await CheckoutService.getCartQuote(
        state.cartItems,
        state.shippingMethodId,
        shippingAddress
      )

      commit(types.QUOTE_UPDATED, { quote })

      if (!state.email) {
        commit(types.EMAIL_CHANGED, { email: quote.email })
      }
    } catch (err) {
      console.error(err)
      dispatch('setCartItems', [])
    } finally {
      commit(types.LOADING_QUOTE_SET, false)
    }
  }, 250),

  async changeShippingAddress({ commit, dispatch }, shippingAddress) {
    commit(types.SHIPPING_ADDRESS_CHANGED, {
      shippingAddress: { ...shippingAddress, certified: false }
    })

    dispatch('persistCheckout')
    dispatch('validateShipping')
  },

  markShippingAddressCertified({ commit, dispatch }) {
    commit(types.SHIPPING_ADDRESS_CHANGED, {
      shippingAddress: { ...state.shippingAddress, certified: true }
    })

    dispatch('persistCheckout')
  },

  async validateShippingAddress({ state, dispatch }) {
    const rules = {
      shippingAddress: [isValidAddress()]
    }

    const result = await ValidationService.validate(state, rules)

    dispatch('setErrors', { shippingAddress: result.errors.shippingAddress })

    return result
  },

  async validateBillingAddress({ state, dispatch }) {
    const rules = {
      billingAddress: state.billingAddress
        ? [isValidAddress()]
        : []
    }

    const result = await ValidationService.validate(state, rules)

    if (!result.isValid) {
      dispatch('setErrors', { billingAddress: result.errors.billingAddress })
    }

    return result
  },

  async validateBillingAddressModel({ state, dispatch }) {
    const result = await CheckoutService.validateAddress(state.billingAddress!)

    const isValid = result.valid
    const errors = { billingAddress: result.errors }

    if (!isValid) {
      dispatch('setErrors', errors)
    }

    return { isValid, errors }
  },

  async certifyShippingAddress({ dispatch, state }) {
    let isValid = true
    let errors = {}

    if (!state.shippingAddress?.certified) {
      const result = await CheckoutService.validateAddress(
        state.shippingAddress
      )
      isValid = result.valid

      if (isValid) {
        dispatch('markShippingAddressCertified')
      } else {
        errors = {
          shippingAddress: result.external
            ? { certified: 'Invalid address' }
            : result.errors
        }
      }
    }

    dispatch('setErrors', errors)

    return { isValid, errors }
  },

  changeEmail({ commit, dispatch }, email) {
    commit(types.EMAIL_CHANGED, { email })

    dispatch('persistCheckout')
  },

  changeBillingAddress({ commit, dispatch }, billingAddress) {
    commit(types.BILLING_ADDRESS_CHANGED, { billingAddress })

    dispatch('persistCheckout')
  },

  changeShippingMethod({ commit, dispatch }, shippingMethodId) {
    commit(types.SHIPPING_METHOD_CHANGED, { shippingMethodId })

    dispatch('persistCheckout')
  },

  changePaymentMethod({ commit, dispatch }, paymentMethod) {
    commit(types.PAYMENT_METHOD_CHANGED, { paymentMethod })

    dispatch('persistCheckout')
  },

  persistCheckout({ state }) {
    const checkout = {
      cartItems: state.cartItems,
      email: state.email,
      shippingAddress: state.shippingAddress,
      shippingMethodId: state.shippingMethodId,
      billingAddress: state.billingAddress,
      paymentMethod: {
        ...state.paymentMethod,
        payment_method_nonce: undefined
      }
    }

    StorageService.persist(CHECKOUT_STORAGE_KEY, checkout)
  },

  clearCheckout({ commit }) {
    StorageService.remove(CHECKOUT_STORAGE_KEY)

    commit(types.CHECKOUT_CLEARED)
  },

  // Validations

  clearErrors({ commit }) {
    commit(types.ERRORS_SET, { errors: {} })
  },

  setErrors({ commit, state }, errors) {
    commit(types.ERRORS_SET, { errors: { ...state.errors, ...errors } })
  },

  validateShipping: debouncePromise(async ({ dispatch }) => {
    // Validate shipping address
    const validateAddressResult = await dispatch('validateShippingAddress')

    if (!validateAddressResult.isValid) {
      return validateAddressResult
    }

    // Certify shipping address
    const certifyAddressResult = await dispatch('certifyShippingAddress')

    return certifyAddressResult
  }, 250),

  async hasValidShippingAddress({ dispatch, getters }) {
    if (!getters.shippingAddress.certified) {
      await dispatch('validateShipping')
    }

    return { isValid: getters.shippingAddress.certified }
  },

  async validateBilling({ dispatch }) {
    if (!state.billingAddress) {
      return { isValid: true }
    }

    // Validate billing address locally
    const validateAddressResult = await dispatch('validateBillingAddress')

    if (!validateAddressResult.isValid) {
      return validateAddressResult
    }

    // Validate billing address model
    const validateAddressModelResult = await dispatch(
      'validateBillingAddressModel'
    )

    return validateAddressModelResult
  },

  async validateEmail({ dispatch, state }) {
    const rules = {
      email: [isValidEmail()]
    }

    const result = await ValidationService.validate(state, rules)

    if (!result.isValid) {
      dispatch('setErrors', result.errors)
    }

    return result
  },

  async validatePayment({ dispatch, state }) {
    const rules = {
      paymentMethod: [isValidPaymentMethod()]
    }

    const result = await ValidationService.validate(state, rules)

    if (!result.isValid) {
      dispatch('setErrors', result.errors)
    }

    return result
  },

  placeOrder({ commit }, placedOrder) {
    commit(types.ORDER_PLACED, { placedOrder })
  }
}

const mutations: MutationTree<CheckoutState> = {
  [types.CART_INITIALIZED](state) {
    state.isInitialized = true
  },

  [types.ITEMS_SET](state, { cartItems }) {
    state.cartItems = cartItems
  },

  [types.EMAIL_CHANGED](state, { email }) {
    state.email = email
  },

  [types.LOADING_QUOTE_SET](state, isLoadingQuote) {
    state.isLoadingQuote = isLoadingQuote
  },

  [types.SHIPPING_ADDRESS_CHANGED](state, { shippingAddress }) {
    state.shippingAddress = shippingAddress
  },

  [types.SHIPPING_METHOD_CHANGED](state, { shippingMethodId }) {
    state.shippingMethodId = shippingMethodId
  },

  [types.PAYMENT_METHOD_CHANGED](state, { paymentMethod }) {
    state.paymentMethod = paymentMethod
  },

  [types.BILLING_ADDRESS_CHANGED](state, { billingAddress }) {
    state.billingAddress = billingAddress
  },

  [types.QUOTE_UPDATED](state, { quote }) {
    state.quote = quote
  },

  [types.CHECKOUT_CLEARED](state) {
    Object.assign(state, getDefaultCheckoutData())
  },

  [types.ORDER_PLACED](state, { placedOrder }) {
    state.placedOrder = placedOrder
  },

  [types.GEO_IP_COUNTRY_SET](state, country) {
    state.geoIpCountry = country
  },

  [types.ERRORS_SET](state, { errors }) {
    state.errors = errors
  }
}

const getters: GetterTree<CheckoutState, RootState> = {
  isInitialized(state) {
    return state.isInitialized
  },

  isLoadingQuote(state) {
    return state.isLoadingQuote
  },

  hasCartItems(state) {
    return state.cartItems.length > 0
  },

  cartItems(state) {
    return state.cartItems
  },

  shippingMethod(state) {
    return (config.shipping_methods || []).find(
      ({ id }) => id === state.shippingMethodId
    )
  },

  paymentMethod(state) {
    return state.paymentMethod
  },

  shippingAddress(state) {
    return state.shippingAddress
  },

  billingAddress(state) {
    return state.billingAddress
  },

  email(state) {
    return state.email
  },

  quote(state) {
    return state.quote
  },

  placedOrder(state) {
    return state.placedOrder
  },

  geoIpCountry(state) {
    return state.geoIpCountry
  },

  errors(state) {
    return state.errors
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
