<template>
  <div class="ChatInstance">
    <div id="LP_DIV_1575593530469" ref="chat"></div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import AnalyticsService from '@/services/AnalyticsService'

export default Vue.extend({
  created() {
    this.$root.$on('openChat', this.openChat)
  },

  beforeDestroy() {
    this.$root.$off('openChat', this.openChat)
  },

  methods: {
    openChat(origin = 'default') {
      AnalyticsService.trackEvent('chat', 'open', origin)
      ;(this.$refs.chat as any).children[0]?.children[0]?.click()
    }
  }
})
</script>

<style lang="postcss">
.ChatInstance {
  @apply w-0 h-0 opacity-0 overflow-hidden;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
}
</style>
