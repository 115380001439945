import PhoneNumber from 'awesome-phonenumber'

import config from '@/config'

import EMAIL_REGEXP from '@/constants/emailRegexp'
import { Address } from '@/types/address'

export type Validator = (options: {
  value: any
  data: any
  resolve: () => void
  reject: (message?: any) => void
}) => Promise<void> | void

export type ValidationErrors = {
  [key: string]: any
}

export type ValidationResults = {
  isValid: boolean
  errors: ValidationErrors
}

export type ValidationRules<T = { [key: string]: any }> = {
  [key in keyof T]: Validator[]
}

export interface ValidationFormInterface {
  validate: () => Promise<ValidationResults>
}

const ValidationService = {
  _validateAttrValue(data: any, attr: string, validator: Validator) {
    const value = data[attr]

    if (typeof validator !== 'function') {
      return undefined
    }

    return new Promise((resolve) => {
      validator({
        value,
        data,
        resolve,
        reject: (messsage) => resolve(messsage)
      })
    })
  },

  async validate<T>(
    data: any,
    rules: ValidationRules<T>
  ): Promise<ValidationResults> {
    const promises = (Object.entries(rules) as [string, Validator[]][]).map(
      async ([attr, validators]) => {
        const useValidator = (validator: Validator) =>
          this._validateAttrValue(data, attr, validator)

        const results = await Promise.all(validators.map(useValidator))
        const errors = results.filter(Boolean)
        return { attr, errors }
      }
    )

    const errors = (await Promise.all(promises))
      .filter(({ errors }) => errors.length > 0)
      .reduce(
        (errorsByAttr, error) => ({
          ...errorsByAttr,
          [error.attr]: error.errors.shift()
        }),
        {}
      )

    return {
      isValid: !Object.keys(errors).length,
      errors
    }
  }
}

export default ValidationService

// Validators

export const isRequired = (message: string): Validator => {
  return async ({ value, resolve, reject }) => {
    !value && value !== 0 ? reject(message || true) : resolve()
  }
}

export const isPhoneNumber = (message: string): Validator => {
  return async ({ value, resolve, reject }) => {
    value && new PhoneNumber(value).isValid() ? resolve() : reject(message)
  }
}

export const isValidAddress = (): Validator => {
  return async ({ value, resolve, reject }) => {
    const constraints = config.address_validation

    const rules: ValidationRules<Address> = {
      first_name: [
        isRequired('Provide your first name'),
        maxLength(
          constraints.max_name_length,
          `Maximum length is ${constraints.max_name_length} chars`
        )
      ],
      last_name: [
        isRequired('Provide your last name'),
        maxLength(
          constraints.max_name_length,
          `Maximum length is ${constraints.max_name_length} chars`
        )
      ],
      organization: [
        maxLength(
          constraints.max_name_length,
          `Maximum length is ${constraints.max_name_length} chars`
        )
      ],
      shipping1: [
        isRequired('Enter your street address'),
        maxLength(
          constraints.max_address_line_length,
          `Maximum length is ${constraints.max_address_line_length} chars`
        ),
        minLength(
          constraints.min_address_line_length,
          `Minimum length is ${constraints.min_address_line_length} chars`
        )
      ],
      shipping2: [
        maxLength(
          constraints.max_address_line_length,
          `Maximum length is ${constraints.max_address_line_length} chars`
        )
      ],
      city: [
        isRequired('Enter your city'),
        maxLength(
          constraints.max_city_length,
          `Maximum length is ${constraints.max_city_length} chars`
        )
      ],
      zip: [
        maxLength(
          constraints.max_zip_length,
          `Maximum length is ${constraints.max_zip_length} chars`
        )
      ],
      state: [
        maxLength(
          constraints.max_state_length,
          `Maximum length is ${constraints.max_state_length} chars`
        )
      ],
      country: [isRequired('Select your country')],
      phone_number: [
        isRequired('Enter your phone number'),
        isPhoneNumber('Invalid phone number format'),
        maxLength(
          constraints.max_phone_length,
          `Maximum length is ${constraints.max_phone_length} chars`
        )
      ]
    }

    const { isValid, errors } = await ValidationService.validate(value, rules)

    isValid ? resolve() : reject(errors)
  }
}

export const isValidPaymentMethod = (): Validator => {
  return async ({ value, resolve, reject }) => {
    if (value.method === 'card') {
      value.credit_card_id || value.payment_method_nonce
        ? resolve()
        : reject({ card: true })
    }

    if (value.method === 'paypal') {
      resolve()
    }
  }
}

export const isValidEmail = (): Validator => {
  return async ({ value, resolve, reject }) => {
    EMAIL_REGEXP.test(value)
      ? resolve()
      : reject({ email: 'Please enter valid email' })
  }
}

export const isMin = (min: number, message: string): Validator => {
  return async ({ value, resolve, reject }) => {
    Number(value) < min ? reject(message) : resolve()
  }
}

export const isMax = (max: number, message: string): Validator => {
  return async ({ value, resolve, reject }) => {
    Number(value) > max ? reject(message) : resolve()
  }
}

export const maxLength = (max: number, message: string): Validator => {
  return async ({ value, resolve, reject }) => {
    String(value).length > max ? reject(message) : resolve()
  }
}

export const minLength = (min: number, message: string): Validator => {
  return async ({ value, resolve, reject }) => {
    String(value).length < min ? reject(message) : resolve()
  }
}
