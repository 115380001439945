<template>
  <div class="SearchInput relative">
    <div class="absolute inset-y-0 left-0 pointer-events-none text-xl">
      <div class="flex w-12 h-full opacity-50">
        <Icon name="search" class="m-auto" />
      </div>
    </div>

    <input
      ref="input"
      class="input input-sm px-12"
      type="text"
      autocomplete="off"
      :value="value"
      v-bind="$attrs"
      data-testid="searchInput"
      v-on="listeners"
      @focus="$event.target.select()"
      @keydown.esc="clearOnESC($event)"
      @keydown.enter="emitSubmit()"
      @input="emitInput($event.target.value)"
    />

    <div
      class="absolute inset-y-0 right-0 flex items-center justify-center pointer-events-none text-xl"
    >
      <div
        v-if="value"
        class="flex w-12 h-full opacity-50 hover:opacity-100 pointer-events-auto cursor-pointer"
        @click="clear()"
      >
        <Icon name="close" :size="16" class="m-auto" />
      </div>

      <div
        v-else-if="hotkey"
        class="border rounded px-1 m-1 text-gray-300 text leading-none py-px mr-3"
      >
        {{ hotkey }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { VueConstructor } from 'vue'

type SearchInput = Vue & {
  $refs: {
    input: HTMLInputElement
  }
}

export default (Vue as VueConstructor<SearchInput>).extend({
  props: {
    value: {
      type: String,
      default: ''
    },

    hotkey: {
      type: String,
      default: undefined
    }
  },

  computed: {
    listeners(): any {
      return {
        ...this.$listeners,
        input: (e: any) => this.$emit('input', e.target.value)
      }
    }
  },

  mounted() {
    if (this.$el.hasAttribute('autofocus')) {
      this.$refs.input.focus()
    }

    if (this.hotkey) {
      document.addEventListener('keydown', this.handleHotkey)
    }
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.handleHotkey)
  },

  methods: {
    emitInput(value: string) {
      this.$emit('input', value)
    },

    emitSubmit() {
      this.$emit('submit')
    },

    clear() {
      this.$refs.input.value = ''
      this.emitInput('')
    },

    clearOnESC(e: any) {
      e.preventDefault()
      e.stopPropagation()

      if (this.$refs.input.value) {
        this.clear()
      } else {
        this.$refs.input.blur()
      }
    },

    handleHotkey(e: any) {
      if (['input', 'textarea'].includes(e.target.tagName.toLowerCase())) {
        return
      }

      if (e.key === this.hotkey) {
        e.preventDefault()
        e.stopPropagation()

        this.$refs.input.focus()
      }
    }
  }
})
</script>
