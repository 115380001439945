<template>
  <transition name="SlideDown">
    <slot />
  </transition>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({})
</script>

<style lang="postcss">
.SlideDown-enter {
  transform: scaleY(0.85) translateY(-6px) translateZ(0);
  opacity: 0;
}

.SlideDown-leave-to {
  transform: scaleY(0.85) translateY(-6px) translateZ(0);
  opacity: 0;
}

.SlideDown-enter-active {
  transition: all 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.075);
  transform-origin: left top;
}

.SlideDown-leave-active {
  transition: all 0.1s ease-in;
  transform-origin: left top;
}

.SlideDown-leave,
.SlideDown-enter-to {
  transform: scaleY(1) translateY(0) translateZ(0);
}
</style>
