<template>
  <div class="AdminLayout">
    <div class="flex flex-row min-h-screen">
      <div
        class="relative flex-none bg-white w-full max-w-xs shadow-lg print:hidden"
      >
        <div class="sticky top-0">
          <div class="p-8 mb-3">
            <a class="flex items-center ml-2" href="/">
              <slot name="logo">
                <CustomInkLogo full />
              </slot>
            </a>
          </div>

          <div class="space-y-3">
            <AdminMenuItem :route="{ name: 'orders' }" icon="orders">
              Orders
            </AdminMenuItem>

            <AdminMenuItem :route="'/sidekiq'" icon="info">
              Sidekiq
            </AdminMenuItem>
          </div>
        </div>
      </div>

      <div class="flex-auto flex flex-col bg-gray-100">
        <div class="flex-none print:hidden">
          <div class="flex items-center px-10 my-8">
            <div class="text-lg font-semibold mb-0">
              <portal-target name="title" class="text-2xl font-semibold mb-0" />
            </div>

            <UserMenu class="ml-auto" />
          </div>

          <div class="text-sm text-secondary mt-2">
            <portal-target name="subtitle" />
          </div>
        </div>

        <div class="flex-auto flex flex-col px-10 print:px-0 pb-10 print:pb-0">
          <router-view class="bg-gray-50 print:bg-white" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import CustomInkLogo from '@/components/CustomInkLogo.vue'
import AdminMenuItem from '@/components/admin/AdminMenuItem.vue'
import UserMenu from '@/components/admin/UserMenu.vue'

export default Vue.extend({
  components: {
    AdminMenuItem,
    CustomInkLogo,
    UserMenu
  },

  metaInfo: {
    titleTemplate(title) {
      return (title ? `${title} | ` : '') + 'Custom Ink International'
    }
  }
})
</script>
