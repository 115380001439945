<template>
  <SelectInput
    class="CountrySelect"
    value-key="code"
    label-key="name"
    v-bind="$attrs"
    :options="countries"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Country } from '@/types/address'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    countries: {
      type: Array as PropType<Country[]>,
      default: () => [] as Country[]
    }
  }
})
</script>
