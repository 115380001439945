export type State = string

export interface Country {
  code: string
  name: string
  dial: string
  states: State[]
}

export interface Address {
  first_name: string
  last_name: string
  organization?: string
  shipping1: string
  shipping2?: string
  zip: string
  city: string
  country: string
  state?: string
  phone_number?: string
}

export interface ShippingAddress extends Address {
  certified?: boolean
}

export interface BillingAddress extends Address {
  billing_address: boolean
}

export function newAddress(): Address {
  return {
    first_name: '',
    last_name: '',
    shipping1: '',
    zip: '',
    city: '',
    country: 'US'
  }
}
