<template>
  <div class="AddressForm">
    <div class="flex flex-wrap lg:flex-nowrap mb-6">
      <div class="w-full lg:w-1/2 mb-5 lg:mb-0">
        <label class="label">First Name</label>

        <TextInput
          v-model="address.first_name"
          placeholder="Enter your first name"
          name="first_name"
          autocomplete="given-name"
          :error="errors.first_name"
          @change="update()"
        />
      </div>

      <div class="w-full lg:w-1/2 mb-5 lg:mb-0 lg:ml-5">
        <label class="label">Last Name</label>

        <TextInput
          v-model="address.last_name"
          placeholder="Enter your last name"
          name="last_name"
          autocomplete="family-name"
          :error="errors.last_name"
          @change="update()"
        />
      </div>
    </div>

    <div class="mb-6">
      <label class="label">Organization</label>

      <TextInput
        v-model="address.organization"
        placeholder="e.g. CalTech, General Electric"
        name="organization"
        autocomplete="organization"
        :error="errors.organization"
        @change="update()"
      />
    </div>

    <div class="flex mb-6">
      <div class="w-2/3">
        <label class="label">Street address</label>

        <TextInput
          v-model="address.shipping1"
          placeholder="Enter street name"
          name="shipping1"
          autocomplete="street-address"
          :error="errors.shipping1"
          @change="update()"
        />
      </div>

      <div class="w-1/3 ml-5">
        <label class="label">Apt. / Suite</label>

        <TextInput
          v-model="address.shipping2"
          placeholder="Apt. / Suite"
          name="shipping2"
          :error="errors.shipping2"
          @change="update()"
        />
      </div>
    </div>

    <div class="flex mb-6">
      <div class="w-2/3">
        <label class="label">City</label>

        <TextInput
          v-model="address.city"
          placeholder="City"
          name="city"
          autocomplete="address-level2"
          :error="errors.city"
          @change="update()"
        />
      </div>

      <div class="w-1/3 ml-5">
        <label class="label">Postal code</label>

        <TextInput
          v-model="address.zip"
          placeholder="ZIP"
          name="zip"
          autocomplete="postal-code"
          :error="errors.zip"
          @change="update()"
        />
      </div>
    </div>

    <div class="flex flex-wrap lg:flex-nowrap mb-6">
      <div class="w-full lg:w-1/2 mb-5 lg:mb-0">
        <label class="label">Country</label>

        <CountrySelect
          v-model="address.country"
          placeholder="Select country"
          name="country"
          autocomplete="country"
          :countries="countries"
          :error="errors.country"
          @input="
            address.state = undefined
            update()
          "
        />
      </div>

      <div class="w-full lg:w-1/2 lg:ml-5">
        <label class="label">State / Province</label>

        <StateSelect
          v-model="address.state"
          placeholder="Select state/province"
          name="state"
          autocomplete="address-level1"
          :country="address.country"
          :error="errors.state"
          @input="update()"
        />
      </div>
    </div>

    <div class="mb-6">
      <label class="label">Phone Number</label>

      <PhoneInput
        v-model="address.phone_number"
        placeholder="Mobile number preferred"
        name="phone_number"
        :error="errors.phone_number"
        :default-country="address.country"
        @input="update()"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import config from '@/config'

import StateSelect from '@/components/StateSelect.vue'
import CountrySelect from '@/components/CountrySelect.vue'
import PhoneInput from '@/components/PhoneInput.vue'

import { newAddress, Address, Country } from '@/types/address'

export default Vue.extend({
  components: {
    StateSelect,
    CountrySelect,
    PhoneInput
  },

  props: {
    value: {
      type: Object as PropType<Address>,
      default: () => newAddress()
    },

    errors: {
      type: Object as any,
      default: () => ({} as any)
    },

    countries: {
      type: Array as PropType<Country[]>,
      default: config.countries
    }
  },

  data() {
    return {
      address: newAddress() as Address
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(address: Address) {
        this.address = { ...address }
      }
    }
  },

  methods: {
    update() {
      this.$emit('input', { ...this.address })
    }
  }
})
</script>
