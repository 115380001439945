<template>
  <component
    :is="route.name ? 'router-link' : 'a'"
    :icon="route.icon"
    class="AdminMenuItem flex items-center font-bold hover:text-blue-700 px-8 py-2"
    v-bind="
      typeof route === 'object'
        ? { to: route }
        : { href: route, target: '_blank' }
    "
  >
    <Icon class="ml-3 mr-4 text-3xl leading-none align-middle" :name="icon" />
    <slot />
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    route: {
      type: [String, Object],
      required: true
    },

    icon: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="postcss">
.AdminMenuItem {
  &.router-link-active {
    @apply text-blue-700;
    box-shadow: inset 5px 0 0 theme('colors.blue.700');
  }
}
</style>
