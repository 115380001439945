import { client, paypalCheckout, Client, PayPalCheckout } from 'braintree-web'

import { apiClient } from '@/services/ApiService'
import BraintreeApi from '@/api/BraintreeApi'

type BraintreeEnv = string
type BraintreeToken = string

interface BraintreeEnvironment {
  braintree_token: BraintreeToken
  braintree_env: BraintreeEnv
}

let clientInstancePromise: Promise<Client>
let configPromise: Promise<BraintreeEnvironment>

const MAX_CREATE_CLIENT_RETRY_ATTEMPTS = 3

export default {
  getToken() {
    if (!configPromise) {
      try {
        configPromise = apiClient
          .get(BraintreeApi.token.path())
          .then((response) => response.data)
      } catch (err) {
        console.error(err)
      }
    }

    return configPromise
  },

  async getClientInstance() {
    let retryCounter = 0

    const retrieveClientInstance = (authorization: string): Promise<Client> => {
      return new Promise((resolve, reject) => {
        client.create({ authorization }, (clientErr, clientInstance) => {
          if (clientErr) {
            if (retryCounter++ < MAX_CREATE_CLIENT_RETRY_ATTEMPTS) {
              retrieveClientInstance(authorization)
            } else {
              reject(clientErr)
            }
          } else {
            resolve(clientInstance)
          }
        })
      })
    }

    if (!clientInstancePromise) {
      const { braintree_token: authorization } = await this.getToken()
      clientInstancePromise = retrieveClientInstance(authorization)
    }

    return clientInstancePromise
  },

  async getPaypalCheckout(): Promise<{
    paypalCheckoutInstance: PayPalCheckout
    braintreeEnv: BraintreeEnv
  }> {
    const clientInstance = await this.getClientInstance()
    const { braintree_env } = await this.getToken()

    return new Promise((resolve, reject) => {
      paypalCheckout.create(
        { client: clientInstance },
        (error, paypalCheckoutInstance) => {
          if (error) {
            reject(error)
          } else {
            resolve({
              paypalCheckoutInstance,
              braintreeEnv: braintree_env
            })
          }
        }
      )
    })
  },

  createPaypalButton(element: Element, options: any) {
    return import(
      /* webpackChunkName: "paypal-checkout-coponents" */ 'paypal-checkout'
    ).then(({ default: paypalButtons }) => {
      paypalButtons.Button.render(options, element)
    })
  }
}
