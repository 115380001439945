<template>
  <img class="CustomInkLogo" :src="logoUrl" title="Custom Ink International" />
</template>

<script lang="ts">
import Vue from 'vue'

import ciLogoFull from '@/assets/images/ci-logo-full.svg'
import ciLogoSign from '@/assets/images/ci-logo.svg'

export default Vue.extend({
  props: {
    full: {
      type: Boolean
    }
  },

  computed: {
    logoUrl(): string {
      return this.full ? ciLogoFull : ciLogoSign
    }
  }
})
</script>

<style lang="postcss">
.CustomInkLogo {
  @apply h-auto align-middle object-contain;
  width: 145px;

  @screen lg {
    width: 172px;
  }
}
</style>
