<template>
  <intersect root-margin="800px 800px 800px 800px" @enter.once="load">
    <div class="LazyImage" :class="{ isLoading }">
      <div class="LazyImage__wrapper">
        <img
          v-if="isVisible"
          class="LazyImage__image"
          :class="fit ? `object-${fit}` : ''"
          :src="src"
          :alt="alt"
        />
        <activity-indicator
          v-if="isLoading && loader"
          class="LazyImage__loading"
        />
      </div>
    </div>
  </intersect>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import Intersect from 'vue-intersect'

import preloadImage from '@/utils/preloadImage'

const SHOW_LOADING_TIMEOUT = 100

const alreadyLoaded: { [key: string]: any } = {}

export default Vue.extend({
  components: {
    Intersect
  },

  props: {
    src: {
      type: String,
      default: undefined
    },

    fit: {
      type: String as PropType<'cover' | 'contain'>,
      default: undefined
    },

    loader: {
      type: Boolean
    },

    alt: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      isVisible: false as boolean,
      isLoading: !alreadyLoaded[this.src] as boolean
    }
  },

  watch: {
    src() {
      this.load()
    }
  },

  methods: {
    async load() {
      this.isVisible = true

      if (!this.src || alreadyLoaded[this.src]) {
        return false
      }

      const timeout = setTimeout(() => {
        this.isLoading = true
        this.$emit('loadStart')
      }, SHOW_LOADING_TIMEOUT)

      await preloadImage(this.src)

      clearTimeout(timeout)

      alreadyLoaded[this.src] = true

      this.isLoading = false
      this.$emit('loaded')
    }
  }
})
</script>

<style lang="postcss">
.LazyImage {
  @apply w-full;
}

.LazyImage__wrapper {
  @apply relative w-full h-full;
}

.LazyImage__image {
  @apply absolute top-0 left-0 w-full h-full transition-opacity duration-150;

  .LazyImage.isLoading & {
    @apply transition-none opacity-0;
  }
}

.LazyImage__loading {
  @apply absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2;
}
</style>
