<template>
  <div class="NavigationStepsBar _background">
    <div
      class="flex items-center select-none bg-gray-100"
      :class="{ 'container': withContainer }"
    >
      <router-link
        v-for="(route, $index) in routes"
        :key="$index"
        :to="route.to"
        class="_link"
      >
        <span v-html="route.label"></span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Route } from '@/types/route'

export default Vue.extend({
  props: {
    withContainer: {
      type: Boolean
    },

    routes: {
      type: Array as PropType<Route[]>,
      default: () => [] as Route[]
    }
  }
})
</script>

<style lang="postcss" scoped>
._background {
  background: linear-gradient(
    to right,
    white 0%,
    white 50%,
    theme('colors.gray.100') 50%,
    theme('colors.gray.100') 100%
  );
}

._link {
  @apply relative flex-grow bg-white py-2 pl-2 pr-0 text-center cursor-pointer uppercase font-bold text-sm tracking-widest text-gray-700 md:p-4;

  &:first-child {
    @apply pl-2;
  }

  &:last-child {
    @apply pr-2;
  }

  &:hover {
    @apply text-gray-900;
  }

  &:not(:last-child)::after {
    @apply absolute z-1 block w-1/2 pointer-events-none;
    content: '';
    top: -1px;
    bottom: -1px;
    left: calc(100% - 1px);
    background-image: url('data:image/svg+xml;utf8,<svg width="17" height="51" viewBox="0 0 17 51" xmlns="http://www.w3.org/2000/svg"><polygon id="Path" fill="%23fff" fill-rule="nonzero" points="0 -0.5 11 25 0 50.5"></polygon><path d="M-1,-4.92457795 L-1,54.9245779 L11.6758377,25 L-1,-4.92457795 Z M0,-4.4408921e-15 L10.5898216,25 L0,50 L0,-4.4408921e-15 Z" fill="%23e0e0e0"></path></svg>');
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  &.router-link-active ~ & {
    @apply text-gray-500 bg-transparent cursor-default;

    &:not(:last-child)::after {
      background-image: url('data:image/svg+xml;utf8,<svg width="17" height="51" viewBox="0 0 17 51" xmlns="http://www.w3.org/2000/svg"><path d="M-1,-4.92457795 L-1,54.9245779 L11.6758377,25 L-1,-4.92457795 Z M0,-4.4408921e-15 L10.5898216,25 L0,50 L0,-4.4408921e-15 Z" fill="%23e0e0e0"></path></svg>');
    }
  }

  &.router-link-exact-active {
    @apply text-gray-900;
  }
}
</style>
