// JsFromRoutes CacheKey d07fc49429bcf3ca001a4f45387455c1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  list: definePathHelper('get', '/admin/api/v1/orders'),
  get: definePathHelper('get', '/admin/api/v1/orders/:id'),
  claim: definePathHelper('put', '/admin/api/v1/orders/:id/claim'),
  saveNote: definePathHelper('put', '/admin/api/v1/orders/:id/save-note'),
}
