<template>
  <div class="CheckoutShippingView">
    <div class="mb-12">
      <div class="flex justify-between items-start">
        <div class="title">
          Shipping
        </div>

        <div class="flex items-center">
          <img
            src="@/assets/images/delivery-icon.svg"
            width="36"
            class="hidden lg:block mr-4"
          />
          <div class="text-sm">
            <span class="uppercase font-bold">Estimated Delivery</span><br />
            <span>3 – 5 weeks</span>
          </div>
        </div>
      </div>

      <AddressForm
        data-testid="shippingAddressForm"
        :countries="shippingCountries"
        :value="shippingAddress"
        :errors="errors.shippingAddress"
        @input="changeShippingAddress($event)"
      />
    </div>

    <div
      v-if="unsupportedDestination"
      class="p-6 my-8 rounded border bg-yellow-100 border-yellow-500"
    >
      <p class="text-lg font-bold">
        Please note
      </p>

      <p class="mt-4">
        To make sure you get the best price for your order, please use our
        domestic checkout form by visiting the link below:
      </p>

      <p class="mt-2">
        <a
          class="link"
          :href="`https://www.customink.com/ndx/?BN=true&cid=${cartItems[0]}#/next/buy`"
        >
          https://www.customink.com/ndx/?cid={{ cartItems[0] }}
        </a>
      </p>
    </div>

    <AppButton
      class="w-full"
      :disabled="isLoadingQuote || isSubmitting || unsupportedDestination"
      data-testid="submitShippingButton"
      primary
      @click="submit()"
    >
      {{ isSubmitting ? 'Validating Shipping Address' : 'Continue to Payment' }}
    </AppButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

import config from '@/config'

import AnalyticsService from '@/services/AnalyticsService'

import AddressForm from '@/components/AddressForm.vue'

import CertifyAddressModal from '@/modals/CertifyAddressModal.vue'

import { ShippingCountry } from '@/types/shipping'

export default Vue.extend({
  components: {
    AddressForm
  },

  data() {
    return {
      isSubmitting: false as boolean,
      hasBeenSubmitted: false as boolean,
      shippingCountries: config.shipping_countries as ShippingCountry[]
    }
  },

  computed: {
    ...mapGetters('checkout', [
      'shippingAddress',
      'isLoadingQuote',
      'cartItems',
      'email',
      'quote'
    ]),

    unsupportedDestination(): boolean {
      return ['US', 'CA'].includes(this.shippingAddress?.country)
    },

    errors(): any {
      return this.hasBeenSubmitted ? this.$store.getters['checkout/errors'] : {}
    }
  },

  methods: {
    ...mapActions('checkout', [
      'changeShippingAddress',
      'changeEmail',
      'validateShipping',
      'validateEmail',
      'clearErrors',
      'markShippingAddressCertified'
    ]),

    async certifyAddress() {
      const isCertified = await this.$modal(CertifyAddressModal, {
        address: this.shippingAddress
      })

      if (isCertified) {
        this.markShippingAddressCertified()
        this.submit()
      }
    },

    async submit() {
      this.isSubmitting = true
      this.hasBeenSubmitted = true

      this.clearErrors()

      const { isValid: isValidEmail } = await this.validateEmail()
      const { isValid: isValidShipping, errors } = await this.validateShipping()

      this.isSubmitting = false

      if (errors.shippingAddress?.certified) {
        return this.certifyAddress()
      }

      if (isValidEmail && isValidShipping) {
        this.$router.push({ name: 'checkout.payment' })
        AnalyticsService.trackEvent('international-checkout', 'submit-shipping')
      } else {
        AnalyticsService.trackEvent(
          'international-checkout',
          'shipping-address-error'
        )
      }
    }
  },

  metaInfo() {
    return {
      title: 'Shipping information | Checkout'
    }
  }
})
</script>
