<template>
  <i
    class="Icon icon"
    :class="['icon-' + name]"
    :style="{ fontSize: size ? `${size}px` : '' }"
  ></i>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    },

    size: {
      type: [Number, String],
      default: undefined
    }
  }
})
</script>
