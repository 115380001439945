<template>
  <div class="TextAreaInput">
    <textarea
      class="input mb-px"
      :class="{ 'isInvalid': error }"
      :value="value"
      v-bind="$attrs"
      v-on="listeners"
    ></textarea>
    <InputErrorMessage :error="error" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: ''
    },

    error: {
      type: [String, Array],
      default: undefined
    }
  },

  computed: {
    listeners(): any {
      return {
        ...this.$listeners,
        input: (e: any) => this.$emit('input', e.target.value)
      }
    }
  }
})
</script>
