<template>
  <div class="Dropdown">
    <div class="Dropdown__selected" @mousedown="toggle()">
      <slot name="selected" />
    </div>

    <SlideDownTransition>
      <div
        v-if="isOpen"
        class="Dropdown__menu"
        :class="{ isOpen, isOnRight: right }"
        @click="close()"
      >
        <slot name="options" :close="close" />
      </div>
    </SlideDownTransition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import SlideDownTransition from '@/components/transitions/SlideDownTransition.vue'

export default Vue.extend({
  components: {
    SlideDownTransition
  },

  props: {
    right: Boolean
  },

  data() {
    return {
      isOpen: false
    }
  },

  mounted() {
    window.addEventListener('mousedown', this.closeByMousedown, true)
    window.addEventListener('keydown', this.closeByEsc, true)
  },

  destroyed() {
    window.removeEventListener('mousedown', this.closeByMousedown, true)
    window.removeEventListener('keydown', this.closeByEsc, true)
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },

    close() {
      this.isOpen = false
    },

    closeByMousedown(e: any) {
      if (!this.$el.contains(e.target)) {
        this.close()
      }
    },

    closeByEsc(e: any) {
      if (e.code === 'Escape') {
        this.close()
      }
    }
  }
})
</script>

<style lang="postcss">
.Dropdown {
  @apply relative;
}

.Dropdown__selected {
  @apply relative flex items-center whitespace-nowrap cursor-pointer select-none;

  &:hover {
    @apply text-black;
  }
}

.Dropdown__menu {
  @apply absolute z-10 left-0 top-full hidden my-4 py-2 min-w-full bg-white border rounded shadow-lg;

  &.isOpen {
    @apply block;
  }

  &.isOnRight {
    @apply left-auto right-0;
  }
}
</style>
