<template>
  <div class="CheckoutView flex flex-col">
    <div v-if="!hasCartItems && !placedOrder" class="m-auto text-center p-6">
      <div class="text-4xl mb-4">
        Your shopping cart is empty
      </div>

      <div class="w-2/3 text-lg text-muted m-auto">
        We could not find any items in your cart. Please continue to the
        homepage to order your design.
      </div>

      <AppButton primary href="https://www.customink.com" class="my-6">
        Return to the Homepage
      </AppButton>
    </div>

    <LargeActivityIndicator v-else-if="isLoadingQuote">
      Loading cart items
    </LargeActivityIndicator>

    <div v-else-if="quote || placedOrder" class="flex-auto flex flex-col">
      <NavigationStepsBar
        v-if="!isReceiptScreen"
        :routes="routeSteps"
        class="border-b"
        with-container
      />

      <div
        v-if="quote"
        class="flex lg:hidden items-center bg-gray-100 border-b border-gray-300 py-2 px-4"
        @click="openOrderSummaryModal()"
      >
        <div class="flex items-center text-sm">
          <Icon name="cart" class="mr-2" size="20" />
          Show order summary
          <Icon name="caret-down" class="ml-2" />
        </div>

        <div class="ml-auto font-bold">
          <span>{{ quote.total_price | currency }}</span>
        </div>
      </div>

      <div v-if="error" class="text-danger text-center font-semibold my-16">
        <div class="text-xl">
          {{ error }}
        </div>

        <div v-if="errorDetail" class="mt-1">
          {{ errorDetail }}
        </div>
      </div>

      <LargeActivityIndicator v-if="isSubmitting">
        Processing your payment
      </LargeActivityIndicator>

      <div :class="{'CheckoutViewContainer': !isReceiptScreen}" v-show="!isSubmitting">
        <div class="flex container">
          <div class="w-full lg:w-1/2 p-4 lg:p-12 lg:pl-8 mx-auto">
            <CheckoutPaymentView v-show="isPaymentScreen" ref="paymentView" />
            <router-view v-if="!isPaymentScreen" @submit="submitOrder()" />
          </div>

          <div v-if="!isReceiptScreen" class="hidden lg:block lg:w-1/2 p-4 lg:p-12">
            <div class="title">
              Order Summary
            </div>

            <OrderSummary v-if="!isLoadingQuote && quote" :quote="quote" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import AnalyticsService from '@/services/AnalyticsService'
import CheckoutService from '@/services/CheckoutService'

import NavigationStepsBar from '@/components/NavigationStepsBar.vue'
import OrderSummary from '@/components/OrderSummary.vue'
import LargeActivityIndicator from '@/components/LargeActivityIndicator.vue'

import OrderSummaryModal from '@/modals/OrderSummaryModal.vue'

// XXX: To make credit card input present in DOM during the whole checkout process,
// we are including it directly and only hidding, instead displaying by router
import CheckoutPaymentView from '@/views/CheckoutPaymentView.vue'
import { PlacedOrder } from '@/types/order'
import { BillingAddress } from '@/types/address'

export default Vue.extend({
  components: {
    NavigationStepsBar,
    OrderSummary,
    LargeActivityIndicator,
    CheckoutPaymentView
  },

  props: {
    cids: {
      type: Array as PropType<string[]>,
      default: () => [] as string[]
    }
  },

  data() {
    return {
      isSubmitting: false as boolean,
      error: undefined as string | undefined,
      errorDetail: undefined as string | undefined,
      placedOrder: undefined as PlacedOrder | undefined,
      routeSteps: [
        { to: { name: 'checkout.shipping' }, label: 'Shipping' },
        { to: { name: 'checkout.payment' }, label: 'Payment' },
        { to: { name: 'checkout.review' }, label: 'Review' }
      ]
    }
  },

  computed: {
    ...mapGetters('checkout', [
      'cartItems',
      'hasCartItems',
      'isLoadingQuote',
      'shippingAddress',
      'shippingMethod',
      'billingAddress',
      'paymentMethod',
      'email',
      'quote'
    ]),

    isPaymentScreen(): boolean {
      return this.$route.name === 'checkout.payment'
    },

    isReviewScreen(): boolean {
      return this.$route.name === 'checkout.review'
    },

    isReceiptScreen(): boolean {
      return this.$route.name === 'checkout.receipt'
    }
  },

  watch: {
    cids: {
      immediate: true,
      handler(cids) {
        if (cids.length) {
          this.setCartItems(cids)
          this.$router.replace({ name: 'checkout' })
        }
      }
    }
  },

  methods: {
    ...mapActions('checkout', ['setCartItems']),

    openOrderSummaryModal() {
      if (this.quote) {
        this.$modal(OrderSummaryModal, {
          quote: this.quote,
          items: this.cartItems
        })
      }
    },

    async submitOrder() {
      this.isSubmitting = true
      this.error = undefined
      this.errorDetail = undefined

      // Refresh card token from payment view
      if (
        this.paymentMethod.method === 'card' &&
        !this.paymentMethod.credit_card_id
      ) {
        const cardInput = (this.$refs.paymentView as any).$refs
          .paymentMethodPicker.$refs.cardInput

        const token = await cardInput.tokenizeCard()

        if (!token) {
          this.isSubmitting = false
          return
        }
      }

      const billingAddress: BillingAddress = this.billingAddress
        ? this.billingAddress
        : { ...this.shippingAddress, billing_address: true }

      try {
        this.placedOrder = await CheckoutService.placeOrder({
          email: this.email,
          items: this.cartItems,
          shippingAddress: this.shippingAddress,
          shippingMethod: this.shippingMethod,
          billingAddress,
          paymentMethod: this.paymentMethod
        })

        // Set variables for tracking
        // const cartItems = [...this.cartItems]

        // Clear Checkout data
        this.$store.dispatch('checkout/clearCheckout')

        // Set placed order
        this.$store.dispatch('checkout/placeOrder', this.placedOrder)

        // Show order receipt
        this.$router.push({ name: 'checkout.receipt' })

        this.isSubmitting = false

        // Track purchase event in GTM
        AnalyticsService.trackEvent('international-checkout', 'order-placed')

        // Track transaction items
        // AnalyticsService.trackReceiptPage(this.placedOrder.id, cartItems, this.placedOrder.email)
      } catch (e) {
        const { message, detail } = (e.response && e.response.data) || {}

        this.errorDetail = detail

        if (message === 'shipping_error') {
          this.error = 'We were unable to verify your shipping address.'
          this.$router.push({ name: 'checkout.shipping' })
          return
        }

        if (message === 'payment_error') {
          this.error = 'There was a problem while processing your payment.'
          this.$router.push({ name: 'checkout.payment' })
          return
        }

        this.error = "We've encountered an error while processing your order."
      } finally {
        this.isSubmitting = false
      }
    }
  },

  metaInfo() {
    return {
      title: 'Checkout'
    }
  }
})
</script>

<style lang="postcss">
  .CheckoutViewContainer {
    @apply lg:relative lg:min-h-screen;

    @screen lg {
      background: linear-gradient(to right, #ffffff 50%, #fcfcfc 50%);
    }

    &::after {
      @apply lg:absolute lg:top-0 lg:bottom-0 lg:border-l lg:border-gray-200 lg:left-1/2;

      @screen lg {
        content: '';
      }
    }
  }
</style>
