import { apiClient } from '@/services/ApiService'
import CheckoutService from '@/services/CheckoutService'
import AdminOrdersApi from '@/api/Admin/OrdersApi'
import { Order, OrderId, OrderItem } from '@/types/admin/orders'

export default {
  async listOrders(params: {
    page?: number | string
    search?: string
  }): Promise<{ orders: any[]; pagination: any }> {
    const response = await apiClient.get(AdminOrdersApi.list.path(), { params })
    return response.data
  },

  async loadOrder(id: OrderId): Promise<Order> {
    const response = await apiClient.get(AdminOrdersApi.get.path({ id }))
    return response.data
  },

  async loadOrderItems(cid: string): Promise<OrderItem[]> {
    try {
      const response = await CheckoutService.getCartQuote([cid])
      return response.items[0].products
    } catch (err) {
      console.error(err)
      return []
    }
  },

  async claimOrder(
    id: OrderId,
    email?: string,
    force?: boolean
  ): Promise<Order | undefined> {
    try {
      const response = await apiClient.put(AdminOrdersApi.claim.path({ id }), {
        email,
        force
      })

      return response.data
    } catch (error) {
      if (error.response?.data?.error === 'already_claimed') {
        const claimedBy = error.response.data.claimed_by
        const message = `This order has already been claimed by ${claimedBy}. Do you want to reclaim it?`

        if (confirm(message)) {
          return this.claimOrder(id, email, true)
        }
      }
    }
  },

  async saveNotes(id: OrderId, note: string): Promise<Order> {
    const response = await apiClient.put(AdminOrdersApi.saveNote.path({ id }), { note })
    return response.data
  }
}
