import { RouteConfig } from 'vue-router'
import store from '@/store'

import CheckoutView from '@/views/CheckoutView.vue'
import CheckoutShippingView from '@/views/CheckoutShippingView.vue'
import CheckoutPaymentView from '@/views/CheckoutPaymentView.vue'
import CheckoutReviewView from '@/views/CheckoutReviewView.vue'
import CheckoutReceiptView from '@/views/CheckoutReceiptView.vue'

import OrdersView from '@/views/admin/OrdersView.vue'
import OrderView from '@/views/admin/OrderView.vue'

import ErrorsView from '@/views/admin/ErrorsView.vue'

import AuthView from '@/views/AuthView.vue'

import AdminLayout from '@/views/layouts/AdminLayout.vue'
import DefaultLayout from '@/views/layouts/DefaultLayout.vue'

const isShippingAddressValid = async () => {
  return (await store.dispatch('checkout/hasValidShippingAddress')).isValid
}

const isEmailAddressValid = async () => {
  return (await store.dispatch('checkout/validateEmail')).isValid
}

const isPaymentValid = async () => {
  return (await store.dispatch('checkout/validatePayment')).isValid
}

const hasPlacedOrder = () => {
  return !!store.getters['checkout/placedOrder']
}

const routes: RouteConfig[] = [
  {
    path: '/auth',
    name: 'auth',
    component: AuthView
  },

  {
    path: '/admin',
    name: 'admin',
    component: AdminLayout,
    meta: {
      isAuthRequired: true
    },
    redirect: {
      name: 'orders'
    },
    children: [
      {
        path: 'orders',
        name: 'orders',
        component: OrdersView
      },
      {
        path: 'errors',
        name: 'errors',
        component: ErrorsView
      },
      {
        path: 'orders/:id',
        name: 'order',
        component: OrderView,
        props: (route) => ({
          orderId: Number(route.params.id)
        })
      }
    ]
  },

  {
    path: '/',
    component: DefaultLayout,
    name: 'home',
    redirect: {
      name: 'checkout'
    },
    children: [
      {
        path: '',
        component: CheckoutView,
        name: 'checkout',
        redirect: {
          name: 'checkout.shipping'
        },
        props: (route) => ({
          cids: (route.query.cid as string)?.split(',') || []
        }),
        children: [
          {
            path: 'shipping',
            name: 'checkout.shipping',
            component: CheckoutShippingView
          },

          {
            path: 'payment',
            name: 'checkout.payment',
            component: CheckoutPaymentView,
            beforeEnter: async (to, from, next) => {
              if (!(await isShippingAddressValid())) {
                return next({ name: 'checkout.shipping' })
              }

              if (!(await isEmailAddressValid())) {
                return next({ name: 'checkout.shipping' })
              }

              next()
            }
          },

          {
            path: 'review',
            name: 'checkout.review',
            component: CheckoutReviewView,
            beforeEnter: async (_to, _from, next) => {
              if (!(await isShippingAddressValid())) {
                return next({ name: 'checkout.shipping' })
              }

              if (!(await isEmailAddressValid())) {
                return next({ name: 'checkout.shipping' })
              }

              if (!(await isPaymentValid())) {
                return next({ name: 'checkout.payment' })
              }

              next()
            }
          },

          {
            path: 'receipt',
            name: 'checkout.receipt',
            component: CheckoutReceiptView,
            beforeEnter: (to, from, next) => {
              hasPlacedOrder() ? next() : next({ name: 'checkout' })
            }
          }
        ]
      }
    ]
  },

  {
    path: '*',
    redirect: '/'
  }
]

export default routes
