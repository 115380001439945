<template>
  <span class="FlagIcon flag-icon" :class="`flag-icon-${code}`"></span>
</template>

<script lang="ts">
import 'flag-icon-css/css/flag-icons.css'

import Vue from 'vue'

export default Vue.extend({
  props: {
    country: {
      type: String,
      required: true
    }
  },

  computed: {
    code(): string {
      return this.country?.toLowerCase()
    }
  }
})
</script>
