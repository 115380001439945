import { apiClient } from '@/services/ApiService'

import AuthApi from '@/api/AuthApi'

import StorageService from '@/services/StorageService'

import { InternalUser } from '@/types/user'

const USER_LOCAL_STORAGE_KEY = 'user'

let currentUser: InternalUser | undefined

const retrieveUserRoutine = async (
  url: string
): Promise<InternalUser | undefined> => {
  try {
    const response = await apiClient.post(url)
    const user = response.data.user as InternalUser
    if (user) {
      currentUser = user
      StorageService.persist(USER_LOCAL_STORAGE_KEY, user)
      return user
    }
  } catch (e) {
    StorageService.remove(USER_LOCAL_STORAGE_KEY)
    return undefined
  }
}

export default {
  signOut() {
    StorageService.remove(USER_LOCAL_STORAGE_KEY)
  },

  getPersistedUser() {
    return StorageService.load(USER_LOCAL_STORAGE_KEY)
  },

  getUser() {
    return retrieveUserRoutine(AuthApi.userInfo.path())
  }
}
