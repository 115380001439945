<template>
  <div class="SelectInput">
    <select
      class="input mb-px"
      :class="{ 'isInvalid': error }"
      v-bind="$attrs"
      v-on="listeners"
    >
      <option v-if="placeholder" :value="undefined" selected disabled>
        {{ placeholder }}
      </option>

      <option
        v-for="option in options"
        :key="valueKey === undefined ? option : option[valueKey]"
        :value="valueKey === undefined ? option : option[valueKey]"
      >
        {{ labelKey === undefined ? option : option[labelKey] }}
      </option>
    </select>

    <InputErrorMessage :error="error" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    options: {
      type: Array as PropType<any>,
      default: () => [] as any[]
    },

    labelKey: {
      type: [String, Number],
      default: undefined
    },

    valueKey: {
      type: [String, Number],
      default: undefined
    },

    placeholder: {
      type: String,
      default: undefined
    },

    error: {
      type: String,
      default: undefined
    }
  },

  computed: {
    listeners(): any {
      return {
        ...this.$listeners,
        change: (e: any) => this.$emit('input', e.target.value),
        input: (e: any) => this.$emit('input', e.target.value)
      }
    }
  }
})
</script>
