<template>
  <div class="StateSelect">
    <SelectInput
      v-if="states.length"
      v-bind="$attrs"
      :options="states"
      v-on="$listeners"
    />

    <TextInput v-else v-bind="$attrs" v-on="$listeners" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import config from '@/config'
import { State } from '@/types/address'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    country: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
    states(): State[] {
      return (
        config.countries?.find((country) => country.code === this.country)
          ?.states || []
      )
    }
  }
})
</script>
