<template>
  <div class="OrdersListView">
    <portal to="title">
      Orders
    </portal>

    <div class="flex flex-grow items-end mb-8">
      <div class="flex space-x-5">
        <div class="max-w-sm w-full">
          <label class="label">
            Search filter
          </label>

          <SearchInput
            :value="querySearch"
            placeholder="Search for ID or email"
            class="w-full"
            hotkey="/"
            @input="searchInputChanged"
          />
        </div>

        <div>
          <label class="label">Filter by Claim</label>

          <select
            class="input input-sm w-48"
            :value="queryClaim"
            @input="claimInputChanged($event.target.value)"
          >
            <option :value="undefined">
              All orders
            </option>

            <option :value="user && user.email">
              Only claimed by me
            </option>

            <option value="unclaimed">
              Only unclaimed
            </option>
          </select>
        </div>

        <div>
          <label class="label">Filter by Note</label>

          <select
            class="input input-sm w-48"
            :value="queryNote"
            @input="noteInputChanged($event.target.value)"
          >
            <option :value="undefined">
              All orders
            </option>

            <option value="true">
              With a note
            </option>

            <option value="false">
              Without a note
            </option>
          </select>
        </div>
      </div>

      <PaginationNav
        v-if="!isLoading && orders.length"
        class="ml-auto flex-none"
        :current-page="pagination.current_page"
        :total-pages="pagination.total_pages"
        @page-select="changePage"
      />
    </div>

    <div v-if="isLoading" class="text-center my-12 py-12">
      <activity-indicator size="large" />
    </div>

    <div v-else-if="orders.length">
      <table class="table mb-4">
        <thead>
          <tr>
            <th class="text-center">
              ID
            </th>

            <th class="text-center">
              CustomInk ID
            </th>

            <th class="text-center cursor-help">
              <span title="Quantity">Qty.</span>
            </th>

            <th class="text-right">
              Total price
            </th>

            <th class="text-center">
              Status
            </th>

            <th class="text-center">
              User's email
            </th>

            <th class="text-center">
              Placed on
            </th>

            <th class="text-center">
              Note
            </th>

            <th class="text-center">
              Claimed by
            </th>
          </tr>
        </thead>

        <tbody>
          <OrdersTableRow
            v-for="order in orders"
            :key="order.id"
            :order="order"
            @update="handleUpdatedOrder"
          />
        </tbody>
      </table>

      <div class="flex items-center">
        <PaginationNav
          v-if="!isLoading && orders.length"
          class="ml-auto"
          :current-page="pagination.current_page"
          :total-pages="pagination.total_pages"
          @page-select="changePage"
        />
      </div>
    </div>

    <div v-else class="text-center text-muted py-12">
      <template v-if="hasFilter">
        No orders found matching current filters
      </template>

      <template v-else>
        There are no orders stored in database
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'
import debounce from 'debounce-promise'

import OrdersService from '@/services/admin/OrdersService'

import { Order, OrdersPagination } from '@/types/admin/orders'

import SearchInput from '@/components/SearchInput.vue'
import PaginationNav from '@/components/admin/PaginationNav.vue'
import OrdersTableRow from '@/components/admin/orders/OrdersTableRow.vue'

export default Vue.extend({
  components: {
    SearchInput,
    PaginationNav,
    OrdersTableRow
  },

  data() {
    return {
      isLoading: false as boolean,
      orders: [] as Order[],
      pagination: {} as OrdersPagination
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    queryPage(): number {
      return Number(this.$route.query.page) || 1
    },

    querySearch(): string | undefined {
      return String(this.$route.query.search || '') || undefined
    },

    queryClaim(): string | undefined {
      return String(this.$route.query.claim || '') || undefined
    },

    queryNote(): string | undefined {
      return String(this.$route.query.note || '') || undefined
    },

    hasFilter(): boolean {
      return Boolean(this.querySearch || this.queryClaim || this.queryNote)
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.loadOrders()
      }
    }
  },

  methods: {
    async loadOrders() {
      this.isLoading = true

      const params = {
        page: this.queryPage,
        search: this.querySearch,
        claim: this.queryClaim,
        note: this.queryNote
      }

      const { orders, pagination } = await OrdersService.listOrders(params)

      this.orders = orders
      this.pagination = pagination

      this.isLoading = false
    },

    submitSearch(change: any = {}) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.queryPage,
          search: this.querySearch,
          claim: this.queryClaim,
          note: this.queryNote,
          ...change
        }
      })
    },

    changePage(page: number) {
      this.submitSearch({ page })
    },

    searchInputChanged: debounce(function (search: string) {
      // @ts-ignore
      this.submitSearch({ search, page: undefined })
    }, 300),

    claimInputChanged(claim?: string) {
      this.submitSearch({ claim, page: undefined })
    },

    noteInputChanged(note?: string) {
      this.submitSearch({ note, page: undefined })
    },

    handleUpdatedOrder(updatedOrder: Order) {
      this.orders = this.orders.map((order: Order) =>
        order.id === updatedOrder.id ? updatedOrder : order
      )
    },

    showOrderDetails(order: Order, e: MouseEvent) {
      const path = this.$router.resolve({
        name: 'order',
        params: { id: String(order.id) }
      }).href

      if (e.metaKey || e.ctrlKey) {
        window.open(path)
      } else {
        window.location.href = path
      }
    }
  }
})
</script>
