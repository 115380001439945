<template>
  <div class="CheckoutPaymentView">
    <div class="mb-12" data-testid="paymentMethodForm">
      <div class="title">
        Payment Method
      </div>

      <PaymentMethodPicker
        ref="paymentMethodPicker"
        :payment="paymentMethod"
        :errors="errors.paymentMethod"
        @change="changePaymentMethod($event)"
      />
    </div>

    <div class="mb-12">
      <div class="title">
        Billing Address
      </div>

      <Checkbox
        :value="!billingAddress"
        @input="toggleSameBillingAddress($event)"
      >
        Same as shipping address
      </Checkbox>

      <AddressForm
        v-if="billingAddress"
        class="mt-6"
        :value="billingAddress"
        :countries="shippingCountries"
        :errors="errors.billingAddress"
        @input="changeBillingAddress($event)"
      />
    </div>

    <AppButton
      class="w-full"
      :disabled="isSubmitting"
      data-testid="submitPaymentButton"
      primary
      @click="submit()"
    >
      Continue to Order Review
    </AppButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

import config from '@/config'

import AnalyticsService from '@/services/AnalyticsService'

import PaymentMethodPicker from '@/components/PaymentMethodPicker.vue'
import AddressForm from '@/components/AddressForm.vue'
import { BillingAddress } from '@/types/address'

export default Vue.extend({
  components: {
    PaymentMethodPicker,
    AddressForm
  },

  data() {
    return {
      isSubmitting: false,
      shippingCountries: config.shipping_countries
    }
  },

  computed: {
    ...mapGetters('checkout', [
      'shippingAddress',
      'billingAddress',
      'paymentMethod',
      'errors'
    ])
  },

  methods: {
    ...mapActions('checkout', [
      'changeBillingAddress',
      'changePaymentMethod',
      'validatePayment',
      'validateBilling',
      'setErrors',
      'clearErrors'
    ]),

    toggleSameBillingAddress(isSameAsShipping: boolean) {
      this.changeBillingAddress(isSameAsShipping ? undefined : { billing_address: true } as Partial<BillingAddress>)
    },

    async retrieveCardToken() {
      try {
        const paymentMethodPicker = this.$refs.paymentMethodPicker as any
        return await paymentMethodPicker.$refs.cardInput.tokenizeCard()
      } catch (e) {
        this.setErrors({ paymentMethod: { card: true } })
        return false
      }
    },

    async submit() {
      this.isSubmitting = true

      this.clearErrors()

      const hasNewCreditCard =
        this.paymentMethod.method === 'card' &&
        !this.paymentMethod.credit_card_id

      if (hasNewCreditCard) {
        const token = await this.retrieveCardToken()

        if (!token) {
          this.isSubmitting = false
          return
        }
      }

      const { isValid: isValidPayment } = await this.validatePayment()
      const { isValid: isValidBilling } = await this.validateBilling()

      if (isValidPayment && isValidBilling) {
        this.$router.push({ name: 'checkout.review' })

        AnalyticsService.trackEvent(
          'international-checkout',
          'submit-payment-method'
        )
      } else {
        AnalyticsService.trackEvent(
          'international-checkout',
          'payment-method-error'
        )
      }

      this.isSubmitting = false
    }
  },

  metaInfo() {
    return {
      title: 'Payment information | Checkout'
    }
  }
})
</script>
