<template>
  <div class="ErrorsListView">
    <portal to="title">
      Errors
    </portal>
    <div class="flex flex-grow items-end mb-8">
      <PaginationNav
        v-if="!isLoading && errors.length"
        class="ml-auto flex-none"
        :current-page="pagination.current_page"
        :total-pages="pagination.total_pages"
        @page-select="changePage"
      />
    </div>

    <div v-if="isLoading" class="text-center my-12 py-12">
      <activity-indicator size="large" />
    </div>

    <div v-else-if="errors.length">
      <table class="table mb-4">
        <thead>
          <tr>
            <th class="text-center">
              ID
            </th>

            <th class="text-center">
              Message
            </th>

            <th class="text-center">
              Retry
            </th>
          </tr>
        </thead>

        <tbody>
          <ErrorsTableRow
            v-for="error in errors"
            :key="error.id"
            :error="error"
          />
        </tbody>
      </table>
    </div>

    <div v-else class="text-center text-muted py-12">
      <template>
        There are no errors
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'
import debounce from 'debounce-promise'

import ErrorsService from '@/services/admin/ErrorsService'

import { Error, ErrorsPagination } from '@/types/admin/errors'

import PaginationNav from '@/components/admin/PaginationNav.vue'
import ErrorsTableRow from '@/components/admin/errors/ErrorsTableRow.vue'

export default Vue.extend({
  components: {
    PaginationNav,
    ErrorsTableRow
  },

  data() {
    return {
      isLoading: false as boolean,
      errors: [] as Error[],
      pagination: {} as ErrorsPagination
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    queryPage(): number {
      return Number(this.$route.query.page) || 1
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.loadErrors()
      }
    }
  },

  methods: {
    async loadErrors() {
      this.isLoading = true

      const params = {
        page: this.queryPage
      }

      const { errors, pagination } = await ErrorsService.listErrors(params)

      this.errors = errors

      this.isLoading = false
    },

    changePage(page: number) {
      this.submitSearch({ page })
    }
  }
})
</script>