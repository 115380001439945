import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from '@/router/routes'
import store from '@/store'

import AnalyticsService from '@/services/AnalyticsService'

import { InternalUser } from '@/types/user'
import AuthService from '@/services/AuthService'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

const getUser = async (): Promise<InternalUser | undefined> => {
  return AuthService.getUser()
}

// Auth middleware
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.isAuthRequired)) {
    const user = await getUser()
    await store.dispatch('auth/init')
    if (!user?.internal) {
      return next({ name: 'auth' })
    }
  }

  next()
})

// Datalayer reset
router.beforeEach((to, from, next) => {
  next()
  AnalyticsService.resetDatalayer()
})

// Virtual pageview tracking
router.afterEach((to) => {
  // Send custom pageview event to Google tag manager
  setTimeout(() => {
    AnalyticsService.trackPageView(to.fullPath)
  }, 600)
})

export default router
