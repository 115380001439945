<template>
  <div class="PhoneInput">
    <div class="relative">
      <div class="absolute left-0 top-0 h-12 w-16 pl-2 flex overflow-hidden">
        <FlagIcon
          :country="countryCode"
          class="_icon relative m-auto shadow-xs"
        />

        <select
          v-model="countryCode"
          class="opacity-0 absolute inset-0 cursor-pointer"
          @input="replaceCountryCode($event.target.value)"
        >
          <option
            v-for="country in countries"
            :key="country.code"
            :value="country.code"
          >
            {{ country.name }} (+{{ country.dial }})
          </option>
        </select>
      </div>

      <TextInput
        type="tel"
        autocomplete="tel"
        input-class="pl-20"
        v-bind="$attrs"
        :value="value"
        v-on="$listeners"
        @focus="handleFocus()"
        @blur="handleBlur()"
        @input="handleInput($event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import AwesomePhoneNumber from 'awesome-phonenumber'

import config from '@/config'

import FlagIcon from '@/components/FlagIcon.vue'

import { Country } from '@/types/address'

interface ProcessedPhoneNumber {
  isValid: boolean
  countryCode: string
  formattedValue: string
}

const processPhoneNumber = (
  number: string,
  countryCode: string
): ProcessedPhoneNumber => {
  // Sanitize international number prefix `00` to `+`
  number = (number || '').replace(/^00/, '+')

  const pn = new AwesomePhoneNumber(number, countryCode)

  return {
    isValid: pn.isValid(),
    countryCode: pn.getRegionCode(),
    formattedValue: pn.getNumber('international')
  }
}

const findCountryByCode = (code: string): Country | undefined => {
  return config.countries.find((country) => country.code === code)
}

export default Vue.extend({
  components: {
    FlagIcon
  },
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: undefined
    },

    defaultCountry: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      countries: config.countries,
      countryCode: String(this.defaultCountry || 'US').toUpperCase(),
      isFocused: false
    }
  },

  computed: {
    phoneNumber(): ProcessedPhoneNumber {
      return processPhoneNumber(this.value, this.countryCode)
    }
  },

  watch: {
    defaultCountry() {
      if (this.defaultCountry && !this.value) {
        this.countryCode = String(this.defaultCountry).toUpperCase()
      }
    }
  },

  mounted() {
    if (this.phoneNumber.isValid) {
      this.countryCode = this.phoneNumber.countryCode

      if (this.value !== this.phoneNumber.formattedValue) {
        this.$emit('input', this.phoneNumber.formattedValue)
      }
    }
  },

  methods: {
    replaceCountryCode(countryCode: string) {
      const oldCountry = findCountryByCode(this.countryCode)
      const country = findCountryByCode(countryCode)

      let value = this.value

      if (oldCountry) {
        value = value.replace(new RegExp(`^\\+${oldCountry.dial}`), '')
      }

      if (value) {
        value = `+${country!.dial}${value}`
        this.handleInput(value)
      }
    },

    handleInput(value: string) {
      const phoneNumber = processPhoneNumber(value, this.countryCode)

      if (phoneNumber.countryCode) {
        this.countryCode = phoneNumber.countryCode
      }

      if (phoneNumber.isValid && !this.isFocused) {
        value = phoneNumber.formattedValue
      }

      this.$emit('input', value)
    },

    handleFocus() {
      this.isFocused = true
    },

    handleBlur() {
      this.isFocused = false

      if (this.phoneNumber.isValid) {
        this.$emit('input', this.phoneNumber.formattedValue)
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
._icon::after {
  content: '';
  position: absolute;
  left: 130%;
  width: 0.8rem;
  height: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792"><path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.8rem;
}
</style>
