<template>
  <modal-layout
    title="Confirm your address"
    width="675"
    scoll
    data-testid="certifyAddressModal"
  >
    <div class="text-lg">
      We were not able to find and verify your shipping address.
      <br class="hidden lg:block" />
      Please certify that your address is correct.
    </div>

    <div class="border rounded p-5 mt-6 mb-4 text-lg">
      <AddressBlock :address="address" />
    </div>

    <template #footer>
      <div class="flex flex-col lg:flex-row-reverse">
        <AppButton
          class="flex-grow w-full lg:w-1/2 mb-3 lg:mb-0 lg:ml-4"
          primary
          @click="$emit('close', true)"
        >
          My Address is Correct
        </AppButton>

        <AppButton class="flex-grow w-full lg:w-1/2" @click="$emit('close')">
          Edit Address
        </AppButton>
      </div>
    </template>
  </modal-layout>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import AddressBlock from '@/components/AddressBlock.vue'

import { Address } from '@/types/address'

export default Vue.extend({
  components: {
    AddressBlock
  },

  props: {
    address: {
      type: Object as PropType<Address>,
      required: true
    }
  }
})
</script>
