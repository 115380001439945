import { apiClient } from '@/services/ApiService'
import AdminErrorsApi from '@/api/Admin/ErrorsApi'
import { Error, ErrorId } from '@/types/admin/errors'

export default {
  async listErrors(params: {
    page?: number | string
  }): Promise<{ orders: any[]; pagination: any }> {
    const response = await apiClient.get(AdminErrorsApi.list.path(), { params })
    return response.data
  },

  async retryFulfillment(id: ErrorId): Promise<Error | undefined> {
    try {
      const response = await apiClient.post(AdminErrorsApi.retryFulfillment.path(id), {
        id
      })

      return response.data
    } catch (error) {
      if (error.response?.data?.error === 'unable_to_retry') {
        const message = `Unable to retry`

        if (confirm(message)) {
          return this.retryFulfillment(id)
        }
      }
    }
  },
}
