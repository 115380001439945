<template>
  <div
    class="PayPalButton"
    :class="{ 'isDisabled': disabled || !isReady }"
  ></div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import BraintreeService from '@/services/BraintreeService'

export default Vue.extend({
  props: {
    amount: {
      type: Number,
      required: true
    },

    disabled: {
      type: Boolean
    }
  },

  data() {
    return {
      isReady: false,
      paypalButtonActions: undefined as any
    }
  },

  watch: {
    disabled() {
      this.paypalButtonActions && this.disabled
        ? this.paypalButtonActions.disable()
        : this.paypalButtonActions.enable()
    }
  },

  async mounted() {
    const {
      paypalCheckoutInstance,
      braintreeEnv
    } = await BraintreeService.getPaypalCheckout()

    await BraintreeService.createPaypalButton(this.$el, {
      env: braintreeEnv,

      commit: true,

      style: {
        size: 'responsive',
        color: 'blue',
        shape: 'rect',
        label: 'pay',
        tagline: 'false'
      },

      validate: (actions: any) => {
        this.paypalButtonActions = actions
      },

      onClick: () => {
        this.$emit('click')
      },

      payment: () => {
        return paypalCheckoutInstance.createPayment({
          flow: 'checkout',
          intent: 'capture',
          currency: 'USD',
          displayName: 'Custom Ink, LLC',
          enableShippingAddress: false,
          amount: this.amount
        } as any)
      },

      onAuthorize: async (data: any) => {
        const payload = await paypalCheckoutInstance.tokenizePayment(data)

        this.$emit('authorize', {
          nonce: payload.nonce,
          email: payload.details.email,
          shippingAddress: payload.details.shippingAddress
        })
      },

      onCancel: (data: any) => {
        this.$emit('cancel', data)
      },

      onError: (error: any) => {
        this.$emit('error', error)
      }
    })

    this.isReady = true
  }
})
</script>

<style lang="postcss">
.PayPalButton {
  @apply relative overflow-hidden inline-block align-middle min-h-16;

  &.isDisabled {
    @apply opacity-50 pointer-events-none;
  }
}
</style>
