<template>
  <div class="CreditCardPreview flex items-center relative">
    <CreditCardIcon
      :card-type="card.card_type"
      class="flex-none mr-3 w-8"
      :title="card.card_type"
    />

    <div v-if="short">
      <span class="font-bold">{{ card.card_type }}</span>
      <span title="Card number ending">**** {{ card.card_last_4 }}</span>
    </div>

    <div v-else>
      <span class="font-bold">{{ card.card_type }}</span>
      ending with
      <span class="font-bold">{{ card.card_last_4 }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import CreditCardIcon from '@/components/CreditCardIcon.vue'

import { CreditCard } from '@/types/payment'

export default Vue.extend({
  components: {
    CreditCardIcon
  },

  props: {
    card: {
      type: Object as PropType<CreditCard>,
      required: true
    },

    short: {
      type: Boolean
    }
  }
})
</script>
