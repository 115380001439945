<template>
  <tr class="text-sm group">
    <td class="text-center">
      {{ error.id }}
    </td>

    <td class="text-right">
      {{ error.error_log }}
    </td>

    <td class="text-center cursor-default" @click.stop>
      <template v-if="isRetried">
        <span class="uppercase badge">
          Retried
        </span>
      </template>

      <template v-else>
        <span class="group-hover:inline">
          <AppButton
            small
            class="min-h-0 py-1 px-3 text-sm -my-1"
            :disabled="isRetrying"
            @click="retryFulfillment(error.id)"
          >
            {{ isRetrying ? 'Retrying' : 'Retry' }}
          </AppButton>
        </span>
      </template>
    </td>
  </tr>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapGetters } from 'vuex'

import ErrorsService from '@/services/admin/ErrorsService'

import { Error } from '@/types/admin/errors'

export default Vue.extend({
  props: {
    error: {
      type: Object as PropType<Error>,
      required: true
    }
  },

  data() {
    return {
      isRetrying: false as boolean,
      isRetried: false as boolean
    }
  },

  methods: {
    async retryFulfillment(errorId: number) {
      this.isRetrying = true

      const retriedFulfillment = await ErrorsService.retryFulfillment(
        errorId
      )

      if (retriedFulfillment) {
        this.$emit('retried', retriedFulfillment)
      }

      this.isRetrying = false
      this.isRetried = true
    },
  }
})
</script>
