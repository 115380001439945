<template>
  <modal-layout scroll>
    <template #header>
      <div class="label mb-0">
        Order summary
      </div>
    </template>

    <OrderSummary v-if="quote" :quote="quote" class="-mx-2 -mt-3" />

    <template #footer>
      <AppButton class="w-full" @click="$emit('close')">
        Close
      </AppButton>
    </template>
  </modal-layout>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import OrderSummary from '@/components/OrderSummary.vue'

import { CartQuote } from '@/types/cart'

export default Vue.extend({
  components: {
    OrderSummary
  },

  props: {
    quote: {
      type: Object as PropType<CartQuote>,
      required: true
    }
  }
})
</script>
