import axios from 'axios'
import AuthService from "@/services/AuthService";

// Set authenticity_token
const csrfTokenMetaElement = document.head.querySelector(
    'meta[name=csrf-token]'
) as HTMLMetaElement

export const apiClient = axios.create({
    headers: { 'X-CSRF-Token': csrfTokenMetaElement.content }
})
