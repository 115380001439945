<template>
  <div class="LargeActivityIndicator text-center m-auto">
    <activity-indicator size="large" class="mx-auto" />

    <div class="mt-6 text-xl">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({})
</script>
