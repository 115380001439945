<template>
  <div class="UserMenu font-semibold space-x-6">
    <template v-if="user">
      <span>
        {{ user.email }}
      </span>

      <a href="/auth/logout" class="link no-underline">
        Sign out
      </a>
    </template>

    <template v-else>
      <router-link :to="{ name: 'auth' }" class="link no-underline">
        Sign in
      </router-link>
    </template>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  computed: {
    user() {
      return this.$store.getters['auth/user']
    }
  }
})
</script>
