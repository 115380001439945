<template>
  <div class="flex min-h-screen w-full bg-gray-100">
    <div
      class="border border-gray-100 rounded-md shadow-xl max-w-sm w-full p-8 pb-12 m-auto text-center bg-white"
    >
      <CustomInkLogo class="w-32 mx-auto my-8" />

      <div class="text-2xl font mb-16">
        International Checkout <br />
        Dashboard
      </div>

      <div class="relative">
        <form action="/auth/auth0" method="post" ref="form">
          <input type="hidden" name="authenticity_token" :value="csrfToken">
          <AppButton
            class="w-full inline-flex items-center justify-center leading-none"
          >
            Sign in
          </AppButton>
        </form>

        <div
          v-if="error"
          class="absolute top-full text-red-600 text-sm mt-2 w-full text-center"
        >
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import CustomInkLogo from '@/components/CustomInkLogo.vue'

export default Vue.extend({
  components: {
    CustomInkLogo
  },

  data() {
    return {
      error: undefined as any,
      csrfToken: ''
    }
  },

  mounted() {
    this.csrfToken = (document.head.querySelector(
      'meta[name=csrf-token]'
    ) as HTMLMetaElement).content as string
  }
})
</script>
