// JsFromRoutes CacheKey 00486aec5a4df1aed55db42ba44fd164
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  signInWithGoogle: definePathHelper('post', '/api/v1/auth/sign-in'),
  signOut: definePathHelper('post', '/api/v1/auth/sign-out'),
  userInfo: definePathHelper('post', '/api/v1/auth/user'),
}
