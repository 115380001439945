<template>
  <div v-show="errorMessage" class="InputErrorMessage">
    {{ errorMessage }}
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

export default Vue.extend({
  props: {
    error: {
      type: [String, Array],
      default: undefined
    }
  },

  computed: {
    errorMessage(): string {
      if (this.error?.length) {
        return Array.isArray(this.error)
          ? (this.error[0] as string)
          : this.error
      } else {
        return ''
      }
    }
  }
})
</script>

<style lang="postcss">
.InputErrorMessage {
  @apply text-red-600 text-sm;
}
</style>
