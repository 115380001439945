<template>
  <div class="CheckoutReceiptView lg:py-12">
    <h1 class="title text-4xl">
      <Icon name="check" class="text-success mr-2" />
      Order Placed
    </h1>

    <p class="text-lg mb-6">
      Thank you! We have successfully received your order.
    </p>

    <p class="text-lg mb-6">
      Our international customer support team will review your order and email
      you an order confirmation within a few days.
    </p>

    <AppButton primary href="https://www.customink.com" class="my-6">
      Return to the Homepage
    </AppButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters('checkout', ['placedOrder'])
  }
})
</script>
