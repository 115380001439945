import { ActionTree, MutationTree, GetterTree } from 'vuex'

import AuthService from '@/services/AuthService'

import { InternalUser } from '@/types/user'
import { RootState } from '@/store'

export interface AuthState {
  user?: InternalUser
}

enum types {
  USER_SET = 'USER_SET'
}

const getInitialState = (): AuthState => ({
  user: undefined
})

const state: AuthState = getInitialState()

const actions: ActionTree<AuthState, RootState> = {
  async init({ commit }) {
    const user = await AuthService.getPersistedUser()

    if (user) {
      commit(types.USER_SET, { user })
      return user
    }
  },

  signOut({ commit, state }) {
    AuthService.signOut()
    commit(types.USER_SET, { user: undefined })
  }
}

const mutations: MutationTree<AuthState> = {
  [types.USER_SET](state, { user }) {
    state.user = user
  }
}

const getters: GetterTree<AuthState, RootState> = {
  user(state) {
    return state.user
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
