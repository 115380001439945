<template>
  <div class="OrderSummaryItem">
    <div class="relative flex flex-wrap lg:flex-nowrap items-center w-full">
      <div
        class="flex justify-center mb-3 lg:mb-0 lg:-ml-px lg:mr-4 w-full lg:w-auto space-x-2"
      >
        <div v-for="view in item.views" :key="view.url" class="w-1/2">
          <LazyImage
            class="bg-white border border-gray-300 rounded w-full lg:w-24 h-32 lg:h-24"
            :src="view.url"
            loader
          />
        </div>
      </div>

      <div class="w-full flex flex-wrap flex-col">
        <div class="w-full text-md font-bold">
          {{ item.product_name }}
        </div>

        <div class="mb-2">
          {{ item.color_name }}
        </div>

        <div v-if="item.price" class="w-full flex justify-between">
          <div>
            Qty {{ item.quantity }} @
            {{ (item.price / item.quantity) | currency }} each
          </div>
          <div class="text-right">
            {{ item.price | currency }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { CartItem } from '@/types/cart'

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    },

    item: {
      type: Object as PropType<CartItem>,
      required: true
    }
  }
})
</script>
