import { apiClient } from '@/services/ApiService'

import CheckoutApi from '@/api/CheckoutApi'
import OrdersApi from '@/api/OrdersApi'

import { Address, ShippingAddress, BillingAddress } from '@/types/address'
import { ShippingMethod } from '@/types/shipping'
import { CartItem } from '@/types/cart'
import { Payment } from '@/types/payment'

export default {
  async validateAddress(address: Address & { billing_address?: boolean }) {
    const payload = {
      address: address
    }

    return (await apiClient.post(CheckoutApi.validateAddress.path(), payload)).data
  },

  async getCartQuote(
    items: CartItem[] | any[],
    shippingMethod?: ShippingMethod | undefined,
    shippingAddress?: Address | undefined
  ) {
    const payload = {
      items: items,
      shipping_address: shippingAddress,
      shipping_method: shippingMethod
    }

    return (await apiClient.post(CheckoutApi.quote.path(), payload)).data
  },

  async placeOrder(data: {
    email: string
    items: CartItem[]
    shippingAddress: ShippingAddress
    shippingMethod: ShippingMethod
    billingAddress: BillingAddress
    paymentMethod: Payment
  }) {
    const payload = {
      email: data.email,
      items: data.items,
      shipping_address: data.shippingAddress,
      shipping_method: data.shippingMethod.id,
      billing_address: data.billingAddress,
      payment: data.paymentMethod
    }

    return (await apiClient.post(OrdersApi.create.path(), payload)).data
  }
}
