import version from '@/constants/localStorageVersion'

const VERSION_KEY = '_localStorageVersion'

const StorageService = {
  init() {
    if (this.load(VERSION_KEY) !== version) {
      this.clear()
    }

    this.persist(VERSION_KEY, version)
  },

  persist(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  },

  load(key: string, defaultValue?: any) {
    try {
      return JSON.parse(localStorage.getItem(key)!) || defaultValue
    } catch (e) {
      return defaultValue
    }
  },

  remove(key: string) {
    localStorage.removeItem(key)
  },

  clear() {
    localStorage.clear()
  }
}

StorageService.init()

export default StorageService
