<template>
  <div class="PaymentMethodPicker border rounded">
    <div class="py-4 px-6 border-b">
      <div
        class="flex-auto flex items-center cursor-pointer"
        data-testid="paymentMethodPaylPal"
        @click="selectMethod('paypal')"
      >
        <Radiobox class="mr-2" :value="payment.method == 'paypal'" />

        <div class="flex-auto font-bold leading-none">
          PayPal
        </div>

        <img src="@/assets/images/paypal-icon.png" class="h-8" alt="PayPal" />
      </div>
    </div>

    <div class="py-4 px-6">
      <div
        class="flex-auto flex items-center cursor-pointer"
        data-testid="paymentMethodCreditCard"
        @click="selectMethod('card', false)"
      >
        <Radiobox class="mr-2" :value="payment.method == 'card'" />

        <div class="flex-auto font-bold leading-none">
          Credit card
        </div>

        <img src="@/assets/images/credit-card-icon.png" class="h-8" alt="Credit Card" />
      </div>

      <div v-show="payment.method == 'card'" class="w-full my-6 px-lg-2">
        <CreditCardInput ref="cardInput" @token="onCreditCardTokenReceived" />

        <InputErrorMessage
          v-if="errors.card"
          class="mt-4"
          error="Please verify your credit card information"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import AnalyticsService from '@/services/AnalyticsService'

import CreditCardInput, {
  CreditCardInfo
} from '@/components/CreditCardInput.vue'

import { Payment, CreditCard, PaymentMethod } from '@/types/payment'

export default Vue.extend({
  components: {
    CreditCardInput
  },

  props: {
    payment: {
      type: Object as PropType<Payment>,
      required: true
    },

    creditCards: {
      type: Array as PropType<CreditCard[]>,
      default: () => [] as CreditCard[]
    },

    errors: {
      type: Object as any,
      default: () => ({} as any)
    }
  },

  computed: {
    selectedCard(): any {
      return this.creditCards.find(
        ({ id }) => id === this.payment?.credit_card_id
      )
    }
  },

  methods: {
    selectMethod(method: PaymentMethod, card?: CreditCard) {
      const payment = {
        method
      } as Payment

      if (method === 'card') {
        // If no card has been specified and user do has some card stored, select the default/first one
        if (!card && this.creditCards.length) {
          card = this.creditCards.find((i) => i.default) || this.creditCards[0]
        }

        // When user picks credit card as payment method, he have two options:
        if (card) {
          // 1) Use existing card - decorate the payment object with id of selected user's card
          //    and emit it immediately.
          payment.credit_card_id = card.id
          payment.card_type = card.card_type
          payment.card_last_4 = card.card_last_4
        } else {
          // 2) Add a new card - in this case we will simply emit payment object, then wait
          //    for the CreditCardInput to retrieve the credit card token from payment provider
          //    and then emit payment object again, decorated with retrieved token.
          ;(this.$refs.cardInput as any).tokenizeCard().catch(() => {})
        }
      }

      this.emitChange(payment)

      AnalyticsService.trackEvent(
        'international-checkout',
        'change-payment-method',
        method
      )
    },

    onCreditCardTokenReceived(cardInfo: CreditCardInfo) {
      this.emitChange({
        method: 'card',
        card_type: cardInfo.details.cardType,
        card_last_4: cardInfo.details.lastFour,
        payment_method_nonce: cardInfo.nonce
      })
    },

    // emitChange(payment: Payment) {
    emitChange(payment: any) {
      this.$emit('change', payment)
    }
  }
})
</script>
