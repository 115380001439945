import config from '@/config'

import { gtmPurchaseData } from '@/utils/gtm'
import { PlacedOrder } from '@/types/order'

export default {
  trackPageView(path: string) {
    window.dataLayer = window.dataLayer || []
    const eventData = {
      'event': 'virtualPageView',
      'page': `/cii${path}`,
      'location': window.location.origin + path
    }

    // Send page view
    window.dataLayer.push(eventData)
  },

  trackEvent(category: string, action?: string, label?: string, value?: any) {
    window.dataLayer = window.dataLayer || []

    const eventData = {
      event: 'eventTracking',
      category,
      action,
      label,
      value
    }

    // Send custom event
    window.dataLayer.push(eventData)
  },

  trackConversion() {
    window.dataLayer = window.dataLayer || []
    // Validate order data (coming from OrderSerializer)
    // Send abstract GTM event
    // this.trackOrderPurchase(order)
  },

  // ---------------------------------- */
  // Abstract GTM Events
  //
  // see: https://github.com/customink/catalog/tree/master/app/assets/javascripts/application/gtm
  // see: https://github.com/customink/rails_frontend/blob/master/app/helpers/analytics/google_helper.rb
  // ---------------------------------- */

  // Abstract GTM Purchase
  trackOrderPurchase(order: PlacedOrder) {
    const data = gtmPurchaseData(order)
    this.sendGtmData('checkout.purchase', data)
  },

  sendGtmData(gtmEvent: string, payload: any) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': gtmEvent,
      [gtmEvent]: payload
    })
  },

  // ---------------------------------- */
  // End of Abstract GTM Events
  // ---------------------------------- */

  // ---------------------------------- */
  // Google Optimize
  // ---------------------------------- */

  // Activate Google Optimize Experiment via GTM
  activateExperiment(name: string) {
    window.dataLayer.push({
      'event': `optimize.activate.${name}`
    })
  },

  getExperimentValue(id: any) {
    return new Promise((resolve) => {
      window.dataLayer.push('event', 'optimize.callback', {
        name: id,
        callback: resolve
      })
    })
  },

  // ---------------------------------- */
  // End of Google Optimize
  // ---------------------------------- */

  resetDatalayer() {
    try {
      if (!config.google_tag_manager_id) {
        return
      }

      if (!window.google_tag_manager) {
        return
      }

      if (!window.google_tag_manager[config.google_tag_manager_id]) {
        return
      }

      window.google_tag_manager[config.google_tag_manager_id].dataLayer.reset()
    } catch (e) {
      throw new Error('Failed to reset dataLayer')
    }
  }
}
