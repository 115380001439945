<template>
  <router-view />
</template>

<script lang="ts">
import Vue from 'vue'

import '@/assets/styles/styles.css'

export default Vue.extend({})
</script>
