<template>
  <div class="flex flex-col min-h-screen">
    <TheHeader class="flex-none border-b hidden lg:block" :email="email" :country="geoIpCountry" />
    <router-view class="flex-auto" />
    <ChatInstance />
    <ci-footer></ci-footer>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'

import TheHeader from '@/components/TheHeader.vue'
import ChatInstance from '@/components/ChatInstance.vue'

export default Vue.extend({
  components: {
    TheHeader,
    ChatInstance
  },

  computed: {
    ...mapGetters('checkout', ['email', 'geoIpCountry'])
  }
})
</script>
