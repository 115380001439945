import config from '@/config'

const countryCodeToName: {
  [key: string]: string
} = (config.countries || []).reduce(
  (result, country) => ({ ...result, [country.code]: country.name }),
  {}
)

export default function countryName(countryCode: string) {
  return countryCodeToName[(countryCode || '').toUpperCase()] || countryCode
}
