<template>
  <div v-show="isVisible" class="PaginationNav">
    <div class="flex items-center mx-auto justify-center">
      <a v-if="hasPrevPage" href class="link" @click.prevent="selectPrevPage()">
        &lsaquo; previous page
      </a>

      <select
        class="input input-sm w-auto mx-4"
        :value="currentPage"
        @change="selectPage($event.target.value)"
      >
        <option v-for="page in pages" :key="page" :value="page">
          {{ page }}
        </option>
      </select>

      <a v-if="hasNextPage" href class="link" @click.prevent="selectNextPage()">
        next page &rsaquo;
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    currentPage: {
      type: Number,
      default: 1
    },

    totalPages: {
      type: Number,
      default: 1
    }
  },

  computed: {
    isVisible(): boolean {
      return this.totalPages > 1
    },

    pages(): number[] {
      return Array.from({ length: this.totalPages }).map(
        (_, index) => index + 1
      )
    },

    hasNextPage(): boolean {
      return this.currentPage < this.totalPages
    },

    hasPrevPage(): boolean {
      return this.currentPage > 1
    }
  },

  methods: {
    selectPage(page: number) {
      this.$emit('page-select', page)
    },

    selectPrevPage() {
      this.selectPage(this.currentPage - 1)
    },

    selectNextPage() {
      this.selectPage(this.currentPage + 1)
    }
  }
})
</script>
