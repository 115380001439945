<template>
  <div
    class="PaymentMethodPreview inline-flex items-center leading-none align-middle"
  >
    <div v-if="payment.method === 'card'">
      <CreditCardPreview :card="payment" :short="short" />
    </div>

    <div v-else-if="payment.method === 'paypal'" class="flex items-center">
      <img src="@/assets/images/paypal-icon.png" height="28" class="mr-2 h-8" />
      Paypal
    </div>

    <div v-else-if="payment.method === 'purchase'">
      Purchase order
    </div>

    <div v-else>
      –
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import CreditCardPreview from '@/components/CreditCardPreview.vue'

import { Payment } from '@/types/payment'

export default Vue.extend({
  components: {
    CreditCardPreview
  },

  props: {
    payment: {
      type: Object as PropType<Payment>,
      required: true
    },

    short: {
      type: Boolean
    }
  }
})
</script>
