<template>
  <div class="AddressBlock leading-normal">
    <strong>{{ address.first_name }} {{ address.last_name }}</strong>
    <br />

    <template v-if="address.organization">
      <strong>{{ address.organization }}</strong>
      <br />
    </template>

    {{ shippingLine }}
    <br />

    {{ address.city }}, {{ address.zip }}
    <br />

    {{ stateAndCountryLine }}
    <br />

    <template v-if="address.email">
      {{ address.email }}
      <br />
    </template>

    <template v-if="address.phone_number">
      {{ address.phone_number }}
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import countryName from '@/filters/countryName'

import { Address } from '@/types/address'

export default Vue.extend({
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true
    }
  },

  computed: {
    shippingLine(): string {
      return [this.address.shipping1, this.address.shipping2]
        .filter(Boolean)
        .join(', ')
    },

    stateAndCountryLine(): string {
      return [
        this.address.state,
        this.address.country && countryName(this.address.country)
      ]
        .filter(Boolean)
        .join(', ')
    }
  }
})
</script>
