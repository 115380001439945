<template>
  <div class="TextInput">
    <input
      class="input"
      :class="[inputClass, { 'isInvalid': error }]"
      :type="type"
      v-bind="$attrs"
      v-on="listeners"
    />
    <InputErrorMessage class="mt-px" :error="error" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'text',
      validator: (type) =>
        ['text', 'number', 'password', 'email', 'tel'].includes(type)
    },

    error: {
      type: [String, Array],
      default: undefined
    },

    inputClass: {
      type: [String, Array, Object],
      default: undefined
    }
  },

  computed: {
    listeners(): any {
      return {
        ...this.$listeners,
        input: (e: any) => this.$emit('input', e.target.value)
      }
    }
  }
})
</script>
