<template>
  <div class="OrderStatusBadge uppercase badge" :class="statusClassName">
    {{ status }}
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

const statusToClassNameMap: any = {
  'placed': 'badge-light',
  'paid': 'badge-success',
  'canceled': 'badge-danger'
}

export default Vue.extend({
  props: {
    status: {
      required: true,
      type: String
    }
  },

  computed: {
    statusClassName(): string {
      return statusToClassNameMap[this.status]
    }
  }
})
</script>
